import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AppSettings } from '@configs/app.setting'
import { AppGuard } from '@core/guards/auth.guard'
import { AdminBusinessDetail } from 'src/app/admin/business/ui/admin-business.model'
import { ResponseModel } from '@core/models/common.model'
import {
  AdminModel,
  ForgotPassword,
  ForgotPasswordForm,
  ForgotPasswordResponse,
  LoginModel,
  LoginResponse,
  MerchantAuth,
  MerchantUpdatePassword,
  MethodListResponse,
  UserContent,
} from '@core/models/user.model'
import { AdminBusinessService } from '@services/admin-business/admin-business.service'
import { AuthService } from '@services/auth/auth.service'
import { CustomCookieService } from '@services/cookie/cookie.service'
import { getAdminPrivilage, getAuthKey } from '@core/utils/auth-stuff'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { BranchRepositoryService } from 'src/app/feature/branch/data-access/repository/branch-repository.service'
import { AdminBusinessRespositoryService } from 'src/app/admin/business/data-access/repository/admin-business-repository/admin-business-respository.service'

@Injectable()
export class UserRepository {
  userIdToModelMap: Map<string, UserContent> = new Map<string, UserContent>()
  adminIdToModelMap: Map<string, ResponseModel<AdminModel>> = new Map<string, ResponseModel<AdminModel>>()

  constructor(
    private _httpClient: HttpClient,
    private _appGuard: AppGuard,
    private _authService: AuthService,
    private _customCookieService: CustomCookieService,
    private _businessService: AdminBusinessService,
    private _businessRepository: AdminBusinessRespositoryService,
    private _branchRepository: BranchRepositoryService
  ) {}

  login(loginModel: LoginModel): Observable<LoginResponse> {
    return this._httpClient.post(AppSettings.USER_LOGIN, loginModel).pipe(
      map((response: LoginResponse | any) => {
        if (response.code == 'OK') {
          this._authService.setUserKey(response.data.userKey)
          this._authService.setDeviceKey(response.data.deviceKey)
          this._authService.setUser(response.data.user)
        }

        return response
      })
    )
  }

  merchantLogin(data: MerchantAuth): Observable<MerchantAuth | any> {
    return this._httpClient.post(AppSettings.MERCHANT_LOGIN, data)
  }

  merchantLoginGoogle(data: any): Observable<MerchantAuth | any> {
    return this._httpClient.post(AppSettings.MERCHANT_LOGIN_GOOGLE, data)
  }

  forgotPassword(forgotPasswordModel: ForgotPasswordForm): Observable<ForgotPasswordResponse> {
    return this._httpClient.post<ForgotPasswordResponse>(AppSettings.OAUTH_FORGOT_PASSWORD_URL_V2, forgotPasswordModel)
  }

  forgotPasswordVerify(token: string): Observable<any> {
    const data = new FormData()
    data.append('token', token)
    return this._httpClient.post(
      `${AppSettings.OAUTH_FORGOT_PASSWORD_URL_V2}/${AppSettings.FORGOT_PASSWORD_VERIFY_V2}`,
      { token: token }
    )
  }

  forgotPasswordConfirm(newPassword: ForgotPassword): Observable<ForgotPassword> {
    return this._httpClient.post<ForgotPassword>(
      `${AppSettings.OAUTH_FORGOT_PASSWORD_URL_V2}/${AppSettings.FORGOT_PASSWORD_CONFIRM_V2}`,
      newPassword
    )
  }

  verifyMerchant(token: string): Observable<any> {
    return this._httpClient.post(AppSettings.VERIFY_MERCHANT, { token: token })
  }

  resendVerifyMerchant(): Observable<any> {
    return this._httpClient.post(`${AppSettings.MERCHANT_UTILITIES}/${AppSettings.RESEND_VERIFY_MERCHANT}`, null)
  }

  emailVerified(): Observable<any> {
    return this._httpClient.get(`${AppSettings.MERCHANT_UTILITIES}/${AppSettings.EMAIL_VALIDATED}`)
  }

  paymentMethod(): Observable<MethodListResponse> {
    return this._httpClient.get<MethodListResponse>(AppSettings.PAYMENT_METHOD)
  }

  paymentType(): Observable<MethodListResponse> {
    return this._httpClient.get<MethodListResponse>(AppSettings.PAYMENT_TYPE)
  }

  templateList(): Observable<MethodListResponse> {
    return this._httpClient.get<MethodListResponse>(AppSettings.MERCHANT_TEMPLATE)
  }

  merchantUpdatePassword(merchantPassword: MerchantUpdatePassword): Observable<any> {
    return this._httpClient.post(
      `${AppSettings.MERCHANT_UTILITIES_URL_V3}/${AppSettings.MERCHANT_UTILITIES_UPADTE_PASSWORD}`,
      merchantPassword
    )
  }

  getUserInfo(): Observable<MerchantAuth | any> {
    return this._httpClient.get(AppSettings.OAUTH_ACCOUNT_URL)
  }

  getStoreInfo() {
    const monitoredBusiness: AdminBusinessDetail | null = this._businessService.getMonitoredBusiness() || null

    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))
    let url: string = `${AppSettings.BUSINESS_UTILITIES_URL}/${AppSettings.BUSINESS_UTILITIES_INFO}`

    let storeId: number = monitoredBusiness?.storeId || 0
    if (monitoredBusiness?.allStore === true) {
      storeId = 0
    }

    let businessId: number = monitoredBusiness?.id || 0
    
    if (getAdminPrivilage() === true) {
      url = `${url}?${AppSettings.BUSINESS_UTILITIES_ID}=${businessId}&${AppSettings.BRANCH_STORE_ID}=${storeId}`
    } else {
      url = `${url}?${AppSettings.BRANCH_STORE_ID}=${storeId}`
    }

    return this._httpClient
      .get(
        url,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) this._appGuard.checkAccess(err.status)
          },
        })
      )
  }
}
