import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CustomModuleComponent } from './custom-modal.component'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
  declarations: [CustomModuleComponent],
  imports: [CommonModule, TranslateModule],
  exports: [CustomModuleComponent],
})
export class CustomModalModule {}
