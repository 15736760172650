import { Injectable } from '@angular/core'
import { sidebarViewAdmin } from './MERCHANT/admin'
import { menuList } from './menu-list'
import { adminMenuList } from './admin-menu-list'
import { AppSettings } from '@configs/app.setting'
import { AdminBusinessService } from '@services/admin-business/admin-business.service'
import { AuthService } from '@services/auth/auth.service'
import { getAdminPrivilage, setAdminPrivilage } from '@core/utils/auth-stuff'
import { getBusinessType } from '@core/utils/business'
import { CommonService } from '@services/common/common.service'
import { ViewService } from '@services/view/view.service'
import { MENU_INDEXES } from '@models/menu-index'
import { Menu } from './models/sidebar.model'

@Injectable()
export class SidebarService {
  CURRENT_MENU_LIST: string = AppSettings.CURRENT_MENU_LIST
  CURRENT_ADMIN_INIT_MENU_LIST: string = AppSettings.CURRENT_ADMIN_INIT_MENU_LIST
  CURRENT_INIT_MENU_LIST: string = AppSettings.CURRENT_INIT_MENU_LIST
  ADMIN_PRIVILAGE: string = AppSettings.ADMIN_PRIVILAGE
  BUSINESS_TYPE: string = AppSettings.BUSINESS_TYPE

  currentMenuList: Array<Object> = []
  currentAdminInitMenuList: Array<Object> = []
  currentInitMenuList: Array<Object> = []
  currentMenuIdList: Array<number> = [MENU_INDEXES.MENU_DASHBOARD_ID]
  currentPermissions: any[] = []
  // adminMonitorPermissions$: Subject<boolean> = new Subject<boolean>()

  protected isAdminPermission: boolean = false
  protected isMonitoredBusinessSelected: boolean = false

  // public adminMonitorPermissions: any[] = []

  adminUnMonitoredBusinessIdList: Array<number> = [
    MENU_INDEXES.MENU_DASHBOARD_ID,
    MENU_INDEXES.MENU_BUSINESS_CREATE,
    MENU_INDEXES.MENU_BUSINESS_MANAGEMENT,
    MENU_INDEXES.MENU_LOG_REPORT,
    MENU_INDEXES.MENU_PERFORMANCE_ID,
    MENU_INDEXES.MENU_USER_PERMISSION_ID,
    MENU_INDEXES.MENU_USER_DETAIL_ID,
    MENU_INDEXES.MENU_USER_ROLE_ID,
  ]

  retailExeptionIds: number[] = [
    MENU_INDEXES.MENU_DASHBOARD_ID,
    // MENU_INDEXES.MENU_PRODUCT_ITEM_LIST_ID,
    MENU_INDEXES.MENU_BRANCH_ID,
    MENU_INDEXES.MENU_PROMO_COUPON_MENU_ID,
    MENU_INDEXES.MENU_DASHBOARD_MENU_ID,
    MENU_INDEXES.MENU_SETTING_ADDRESS_ID,
    MENU_INDEXES.MENU_FNB_ANALYTIC_ID,
    MENU_INDEXES.MENU_ORDER_CUSTOMER_MENU_ID,
    MENU_INDEXES.MENU_APPEREANCE_FNB_ID,
    MENU_INDEXES.MENU_EMPLOYEE_ID,
    // MENU_INDEXES.MENU_PRODUCT_CUSTOM_ID,
    MENU_INDEXES.MENU_FNB_PRODUCT_CATEGORY_ID,
    MENU_INDEXES.MENU_FNB_PRODUCT_MEDIA_ID,
    MENU_INDEXES.MENU_FNB_INTEGRATION_ID,
    MENU_INDEXES.MENU_FNB_SETTING_ID,
    MENU_INDEXES.MENU_FNB_USER_PERMISSION_ID,
    MENU_INDEXES.MENU_FNB_PRODUCT_ID,
    MENU_INDEXES.MENU_TABLE_MANAGEMENT_ID,
    MENU_INDEXES.MENU_FNB_INTEGRATION_DELIVERY_ID,
    MENU_INDEXES.MENU_USER_PERMISSION_ID,
  ]

  menuExeptionIds: number[] = [
    MENU_INDEXES.MENU_DASHBOARD_ID,
    MENU_INDEXES.MENU_PRODUCT_LIST_ID,
    // MENU_INDEXES.MENU_INTEGRATION_ID,
    MENU_INDEXES.MENU_PROMO_COUPON_ID,
    MENU_INDEXES.MENU_SETTING_DELIVERY_ID,
    MENU_INDEXES.MENU_APPEREANCE_ID,
    MENU_INDEXES.MENU_DASHBOARD_RETAIL_ID,
    MENU_INDEXES.MENU_ANALYTIC_ID,
    MENU_INDEXES.MENU_ORDER_CUSTOMER_RETAIL_ID,
    // MENU_INDEXES.MENU_PRODUCT_CATEGORY_ID,
    // MENU_INDEXES.MENU_PRODUCT_MEDIA_ID,
    MENU_INDEXES.MENU_INTEGRATION_ID,
    MENU_INDEXES.MENU_SETTING_ID,
    MENU_INDEXES.MENU_USER_PERMISSION_ID,
    MENU_INDEXES.MENU_USER_DETAIL_ID,
    MENU_INDEXES.MENU_USER_ROLE_ID,
    MENU_INDEXES.MENU_PRODUCT_ID,
  ]

  constructor(
    private _businessService: AdminBusinessService,
    private _authService: AuthService,
    private _viewService: ViewService,
    protected commonService: CommonService,
  ) {
    // this.disableByRegionHandler()
  }

  protected PermissionAccessHandler(menuIdx: number, accessList: any[]) {
    // this.currentSideMenuList[menuIdx].
  }

  public getSidebarViewAdmin(): Array<Object> {
    return sidebarViewAdmin
  }

  private isActivePageIncludeOnAdminMenu(): boolean {
    return this.adminUnMonitoredBusinessIdList.some((id) => id === this._viewService.getActivePage())
  }

  public getSidebarAllMenu(): Menu[] {
    const monitoredBusiness = this._businessService.getMonitoredBusiness()
    const isAdmin = getAdminPrivilage() === true

    // || this.isActivePageIncludeOnAdminMenu()
    if (isAdmin && (!monitoredBusiness || monitoredBusiness == null)) {
      return this.getAdminInitMenuList()
      // && !this.isActivePageIncludeOnAdminMenu()
    } else if (isAdmin && monitoredBusiness && monitoredBusiness != null) {
      // this.getCurrentMenuIdList(this.adminMonitorPermissions$, true)
      return this.getCurrentMenuList()
    } else if (!isAdmin) {
      var menu: any = []
      let exceptionIdList: number[] = this.menuExeptionIds
      this.getCurrentMenuList().map((currentMenu: any) => {
        if (!currentMenu.subtitle) {
          menu.push(currentMenu)
          return
        }

        currentMenu.subtitle = currentMenu.subtitle.filter((subMenu) => {
          let result: boolean = true
          exceptionIdList.map((exceptionId) => {
            if (exceptionId === subMenu?.id) result = false
          })

          return result
        })

        menu.push(currentMenu)
      })

      return menu
    } else {
      return menu
    }
  }

  setAdminMenu() {
    if (this._businessService.getMonitoredBusiness()) {
      return this.getCurrentMenuList()
    } else {
      return this.getAdminInitMenuList()
    }
  }

  getMenuListBySuperAdminPermissions(): any[] {
    let result: any[] = []

    for (let permission in this.currentPermissions) {
      menuList.map((menu: any) => {
        if (menu.codes.includes(permission) && !result.some((resMenu) => resMenu.id === menu.id)) {
          result.push(menu)
        }
      })
    }

    if (result.length > 0) {
      result.sort((a, b) => a.id - b.id)
    }

    return result
  }

  public getCurrentMenuIdList(permissionList: any, noPermissionNeeded: boolean = false) {
    this.currentPermissions = permissionList
    const businessType: string | null = getBusinessType() || null
    const exeptionIds: number[] = this.menuExeptionIds
    var mainMenuList = menuList
    this.currentMenuList.push(menuList[0])

    if (noPermissionNeeded) {
      // this.currentMenuList = []

      // menuList.map((menu) => {
      //   if (
      //     !this.currentMenuList.some((selectedMenu: any) => selectedMenu.id == menu.id) &&
      //     !exeptionIds.some((id) => id == menu.id)
      //   ) {
      //     // Change title as business type equal to MENU.
      //     // Will refactor later
      //     if (menu.id == MENU_INDEXES.MENU_PRODUCT_ID && businessType == AppSettings.BUSINESS_TYPE_MENU) {
      //       menu.title = 'BUSINESS_SIDEBAR.ITEMS'
      //     }
      //     this.currentMenuList = [...this.currentMenuList, menu]
      //   }

      // })
      this.currentMenuList = [
        {
          menuClass: 'BUSINESS_SIDEBAR.MENU_CLASS_SUPER_ADMIN',
          separator: false,
        },
        ...this.getAdminInitMenuList().filter((menu) => menu.id < MENU_INDEXES.MENU_USER_PERMISSION_ID),
        {
          menuClass: 'BUSINESS_SIDEBAR.MENU_CLASS_ADMIN',
          separator: true,
        },
        ...this.getMenuListBySuperAdminPermissions(),
      ]
    } else {
      for (let permission in permissionList) {
        if (permission.includes(AppSettings.PREFIX_ADMIN_IDESHOP)) {
          mainMenuList = adminMenuList as any[]
          this.isAdminPermission = true
          setAdminPrivilage(true)
          break
        }
      }

      if (getAdminPrivilage() === true) {
        this.menuAdminHandler()
        return
      } else {
        for (let permission in permissionList) {
          mainMenuList.map((menu) => {
            if (
              menu.codes.some((code) => code == permission) &&
              !this.currentMenuList.some((selectedMenu: any) => selectedMenu.id == menu.id) &&
              !exeptionIds.some((id) => id == menu.id)
            ) {
              // Change title as business type equal to MENU.
              // Will refactor later
              if (menu.id == MENU_INDEXES.MENU_PRODUCT_ID && businessType == AppSettings.BUSINESS_TYPE_MENU) {
                menu.title = 'BUSINESS_SIDEBAR.ITEMS'
              }
              this.currentMenuList = [...this.currentMenuList, menu]
            }
          })
        }
      }
    }

    if (!getAdminPrivilage()) {
      this.currentMenuList.map((menu: any) => {
        if (menu?.subtitle) {
          menu.subtitle = menu.subtitle.filter((subMenu) =>
            this.subMenuHandler(subMenu, exeptionIds, noPermissionNeeded)
          )
          menu.subtitle.sort((leftSubMenu, rightSubMenu) => leftSubMenu.id - rightSubMenu.id)
        }
      })

      this.currentMenuList = this.currentMenuList.sort((menuA: any, menuB: any) => menuA.id - menuB.id)
    }

    if (!this.isAdminPermission && !noPermissionNeeded) {
      this.setMerchantInitMenuList(this.currentInitMenuList)
    }

    this.setCurrentMenuList(this.currentMenuList)
  }

  private subMenuHandler(
    menu: any,
    exeptionIds: number[],
    noPermissionNeeded: boolean,
    isAdmin: boolean = false
  ): boolean {
    let result = false
    const isMenuOnExeption: boolean = exeptionIds.some((id) => menu.id == id)

    if (noPermissionNeeded) {
      if (!isMenuOnExeption) {
        result = true
      }
    } else {
      for (let permission in this.currentPermissions) {
        const isMenuOnPermission: boolean = menu.codes.some((menuCode) => menuCode == permission)

        if (isMenuOnPermission && !isMenuOnExeption) {
          result = true
        }
      }
    }

    return result
  }

  permissionDummy: any = {
    IDESHOP_SA_BUSINESS: ['IDESHOP_SA_BUSINESS_READ'],
    IDESHOP_SA_INTEGRATION_DELIVERY_PARENT: [
      'IDESHOP_SA_INTEGRATION_DELIVERY_READ',
      'IDESHOP_SA_INTEGRATION_DELIVERY_UPDATE',
    ],
    IDESHOP_SA_PRODUCT: [
      'IDESHOP_SA_PRODUCT_CREATE',
      'IDESHOP_SA_PRODUCT_DELETE',
      'IDESHOP_SA_PRODUCT_READ',
      'IDESHOP_SA_PRODUCT_UPDATE',
    ],
    IDESHOP_SA_INTEGRATION: [
      'IDESHOP_SA_INTEGRATION_CREATE',
      'IDESHOP_SA_INTEGRATION_READ',
      'IDESHOP_SA_INTEGRATION_UPDATE',
    ],
  }

  menuAdminHandler() {
    const filteredMenuSet = new Set<number>() // To track added menu IDs
    const { currentPermissions } = this // Assume currentPermissions is an object like { permissionCode: true }

    // Utility function to check if a menu or its subtitles has valid permissions
    const hasValidPermission = (menu): boolean => {
      const menuCodes = menu.codes || []
      return menuCodes.some((code: string) => currentPermissions.hasOwnProperty(code))
    }

    // Step 1: Filter menus with empty codes
    const filteredAdminMenuList = adminMenuList.filter((menu) => {
      if (menu.codes.length === 0) {
        filteredMenuSet.add(menu.id)
        return true // Include menus with empty codes
      }
      return false
    })

    // Step 2: Process menus with valid permissions
    adminMenuList.forEach((menu) => {
      // Check parent menu permissions
      if (hasValidPermission(menu) && !filteredMenuSet.has(menu.id)) {
        filteredMenuSet.add(menu.id)
        const filteredMenu = { ...menu } // Clone the menu object

        // Step 3: Process subtitle ONLY if parent menu has permission
        if (menu.subtitle && Array.isArray(menu.subtitle)) {
          filteredMenu.subtitle = menu.subtitle.filter((sub) =>
            sub.codes.some((code: string) => currentPermissions.hasOwnProperty(code))
          )
        }

        filteredAdminMenuList.push(filteredMenu)
      }
    })

    // Step 4: Sort the final menu list by 'id'
    const sortedMenuList = filteredAdminMenuList.sort((a, b) => a.id - b.id)

    // Step 5: Update the state with the final menu list
    this.setAdminInitMenuList(filteredAdminMenuList)
    this.setCurrentMenuList(sortedMenuList)
    this.currentMenuList = sortedMenuList
  }

  setCurrentMenuList(menuList: any) {
    localStorage.setItem(this.CURRENT_MENU_LIST, JSON.stringify(menuList))
  }

  getCurrentMenuList(): any {
    let result: any = localStorage.getItem(this.CURRENT_MENU_LIST)
    try {
      result = JSON.parse(result)

      if (!result || !result?.length) {
        result = []
        this.commonService.clearLocalData()
      }

    } catch(e) {
      this.commonService.clearLocalData()
    }

    return result
  }

  setAdminInitMenuList(menuList: any) {
    localStorage.setItem(this.CURRENT_INIT_MENU_LIST, JSON.stringify(menuList))
  }

  getAdminInitMenuList(): any {
    let result: any = localStorage.getItem(this.CURRENT_INIT_MENU_LIST)
    return JSON.parse(result)
  }

  setMerchantInitMenuList(menuList: any) {
    localStorage.setItem(this.CURRENT_INIT_MENU_LIST, JSON.stringify(menuList))
  }

  getMerchantInitMenuList(): any {
    let result: any = localStorage.getItem(this.CURRENT_INIT_MENU_LIST)
    return JSON.parse(result)
  }

  // This will be temporarely, please consider to make a better version
  disableByRegionHandler() {
    const region = localStorage.getItem(AppSettings.COOKIE_COUNTRY)

    if (region !== AppSettings.COUNTRY_ID_INDONESIA) {
      this.menuExeptionIds.push(MENU_INDEXES.MENU_INTEGRATION_DELIVERY_ID)
    }
  }

  // Set Admin Monitor Permissions
  // setAdminMonitorPermissions() {
  //   this.adminMonitorPermissions$.subscribe({
  //     next: permission => this.
  //   })
  // }
}
