import { Injectable } from '@angular/core'
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router'
import { UserRepository } from '@repository/user-repository/user-repository.service'
import { getMonitoredBusiness, setBusinessLogo } from '@utils/user-stuff'
import { Observable, tap } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class StoreInfoResolver implements Resolve<any> {
  constructor(private _userRepository: UserRepository) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this._userRepository.getStoreInfo().pipe(
      tap({
        next: (response: any) => {
          if (!getMonitoredBusiness()?.allStore) {
            setBusinessLogo(response.data?.brandLogoUrl)
          }
        },
      })
    )
  }
}
