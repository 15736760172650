import { Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from '@angular/core'
import { ErrorService } from '@services/error/error.service'
import { NgxSpinnerService } from 'ngx-spinner'

@Component({
  selector: 'app-error-500',
  templateUrl: './error-500.component.html',
  styleUrls: ['./error-500.component.scss'],
})
export class Error500Component {
  @Input() visible!: boolean
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter()

  constructor(private _spinner: NgxSpinnerService, private _errorService: ErrorService) {}

  reload(): void {
    if (
      this._errorService.errorUrlEndpoint$.value
    ) {
      this._errorService.reloadConnection$.next(true)

      this._errorService.errorRequestHandler(
        this._errorService.errorUrlEndpoint$.value,
        this._errorService.errorCodeEndpoint$.value,
        this._errorService.errorMessageEndpoint$.value
      )
    }
  }
}
