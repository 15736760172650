export const API_PUBLIC_LIST: string[] = [
  '/api/utilities/country',
  '/api/utilities/currency',
  '/api/utilities/idepos/log/download/',
  '/api/utilities/ip',
  '/api/utilities/new-list-tier',
  '/api/utilities/phone-number',
  '/api/utilities/template',
  '/api/public/template/general',
  '/api/utilities/tier',
  '/api/utilities/timezone',
  '/api/oauth/login/merchant',
  '/api/oauth/login/merchant-google',
  '/api/oauth/login/pbb',
  '/api/oauth/otp',
  '/api/oauth/register/merchant',
  '/api/oauth/register/merchant-google',
  '/api/oauth/resend',
  '/api/oauth/utilities/forgot-password',
  '/api/oauth/utilities/forgot-password/confirm',
  '/api/oauth/utilities/forgot-password/verify',
  '/api/oauth/verify',
  '/api/health-check'
]
