import { HttpStatusCode } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AppSettings } from '@configs/app.setting'
import { removesLocalstorage } from '@core/utils/app-stuff'
import { COOKIE_NAME_LIST } from '@models/auth.model'
import { TranslateService } from '@ngx-translate/core'
import { HealthCheckRepositoryService } from '@repository/health-check-repository/health-check-repository.service'
import { CustomCookieService } from '@services/cookie/cookie.service'
import { CookieService } from 'ngx-cookie'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  isError500$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  errorUrlEndpoint$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null)
  errorCodeEndpoint$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null)
  errorMessageEndpoint$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null)
  reloadConnection$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private _isServiceDown!: boolean

  constructor(
    private _healthCheckRepository: HealthCheckRepositoryService,
    private _toastr: ToastrService,
    private _spinner: NgxSpinnerService,
    private _router: Router,
    private _customCookieService: CustomCookieService,
    private _cookieService: CookieService,
    private _translateService: TranslateService
  ) {}

  errorRequestHandler(url: string, errorCode: number | null, errorMessage: string | null): void {
    this._spinner.show()
    this.errorUrlEndpoint$.next(url)
    this.errorCodeEndpoint$.next(errorCode)
    this.errorMessageEndpoint$.next(errorMessage)

    switch (true) {
      case url.includes('api-logs'):
        this._healthCheckRepository.logsBackendService().subscribe()
        break

      case url.includes('api-sms'):
        this._healthCheckRepository.smsBackendService().subscribe()
        break

      case url.includes('api-queue'):
        this._healthCheckRepository.queBackendService().subscribe()
        break

      case url.includes('apiconnect'):
        this._healthCheckRepository.ideConnectBackendService().subscribe({
          next: () => this._successHealthCheck(),
          error: () => this._errorHealthCheck(),
        })
        break

      case url.includes('apicommerce'):
        this._healthCheckRepository.commerceBackendService().subscribe({
          next: () => this._successHealthCheck(),
          error: () => this._errorHealthCheck(),
        })
        break

      case url.includes('api-kiosk'):
        this._healthCheckRepository.kioskBackendService().subscribe({
          next: () => this._successHealthCheck(),
          error: () => this._errorHealthCheck(),
        })
        break

      case url.includes('api-kds-eds'):
        this._healthCheckRepository.kdsEdsBackendService().subscribe({
          next: () => this._successHealthCheck(),
          error: () => this._errorHealthCheck(),
        })
        break

      default:
        this._healthCheckRepository.mainBackendService().subscribe({
          next: () => this._successHealthCheck(),
          error: () => this._errorHealthCheck(),
        })
        break
    }
  }

  private _errorHealthCheck(): void {
    this.isError500$.next(true)
    this._isServiceDown = true
  }

  private _successHealthCheck(): void {
    if (!this._isServiceDown && this.errorCodeEndpoint$.value && this.errorMessageEndpoint$.value) {

      // Will uncomment on the next phase when our bootstrap has been migrated to angular-bootstrap
      // this._toastr.error(
      //   this._translateService.instant(`ERROR_MESSAGE.${this.errorMessageEndpoint$.value}`),
      //   `Error ${this.errorCodeEndpoint$.value}`
      // )

      // Check if status 401 or unauthorized, it will back to login and reset cookie
      if (this.errorCodeEndpoint$.value === HttpStatusCode.Unauthorized) {
        this._cookieService.removeAll()
        COOKIE_NAME_LIST.map((cookieName) => this._customCookieService.removeItem(cookieName))
        removesLocalstorage([AppSettings.TIMEZONE_LIST])
        this._router.navigateByUrl('/login')
      }
    } else {
      this.isError500$.next(false)
    }
    this._spinner.hide()
  }
}
