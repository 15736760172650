import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AppSettings } from '@configs/app.setting'
import { AppGuard } from '@core/guards/auth.guard'
import { AdminBusinessDetail, Status } from 'src/app/admin/business/ui/admin-business.model'
import { Pagination, ReqPagination, ResponseModel } from '@models/common.model'
import { CustomCookieService } from '@services/cookie/cookie.service'
import { getAuthKey } from '@core/utils/auth-stuff'
import { Observable } from 'rxjs'
import { tap, map } from 'rxjs/operators'
import { BranchRepositoryService } from 'src/app/feature/branch/data-access/repository/branch-repository.service'
import { StoreRepositoryService } from 'src/app/feature/branch/data-access/repository/store-repository/store-repository.service'
import { DokuPaymentPayload } from 'src/app/admin/business/interface/business.model'
import { INTEGRATION_PAYMENT_ENDPOINTS } from 'src/app/feature/integration/utils/integration-payment-endpoints'
import { PaymentFee } from 'src/app/feature/integration/models/integration-payment.model'

@Injectable({
  providedIn: 'root',
})
export class AdminBusinessRespositoryService {
  constructor(
    private _httpClient: HttpClient,
    private _branchRepository: BranchRepositoryService,
    private _storeRepository: StoreRepositoryService,
    private _customCookieService: CustomCookieService,
    protected appGuard: AppGuard
  ) {}

  adminBusinessList(pageConfig: ReqPagination | any): Observable<Pagination> {
    let url: string = `${AppSettings.ADMIN_BUSINESS_URL}?`

    for (const config in pageConfig) {
      if (url.charAt(url.length - 1) === '?') {
        url = `${url}${config}=${pageConfig[config]}`
      } else {
        url = `${url}&${config}=${pageConfig[config]}`
      }
    }

    return this._httpClient.get<Pagination>(url)
  }

  businessList(pageConfig: ReqPagination, businessId?: number): Observable<Pagination> {
    // Used by monitored business dropdown

    let adminPrivilage: any = localStorage.getItem('admin-privilage')
    adminPrivilage = JSON.parse(adminPrivilage)

    // Use store list if user is not super admin
    if (!adminPrivilage || !businessId) {
      return this._branchRepository.branchStoreList(pageConfig)
    }

    let url: string = `${AppSettings.BUSINESS_UTILITIES_URL}/${AppSettings.BUSINESS_UTILITIES_LIST}?`
    for (const config in pageConfig) {
      if (url.charAt(url.length - 1) === '?') {
        url = `${url}${config}=${pageConfig[config]}`
      } else {
        url = `${url}&${config}=${pageConfig[config]}`
      }
    }

    if (businessId) {
      url = `${url}&businessId=${businessId}`
    }
    return this._httpClient.get<Pagination>(url)
  }

  getMerchantPaymentIntegration(businessId: number, storeId: number): Observable<any> {
    let url: string = `/api-payment/portal/v1/admin/business-section/${businessId}${INTEGRATION_PAYMENT_ENDPOINTS.paymentFee.superAdmin}?storeId=${storeId}`

    return this._httpClient.get<any>(url)
  }

  addAdminBusiness(adminBusiness: AdminBusinessDetail): Observable<any> {
    return this._httpClient.post(AppSettings.ADMIN_BUSINESS_URL, adminBusiness)
  }

  activateBusiness(businessId: number): Observable<any> {
    return this._httpClient.post(`${AppSettings.ADMIN_BUSINESS_URL}/${businessId}/activate-account`, {})
  }

  getAdminBusinessById(businessId: number): Observable<any> {
    const country: string = localStorage.getItem('country')!
    let url: string = ''

    url = `${AppSettings.ADMIN_BUSINESS_URL}/${businessId}`

    return this._httpClient.get(url)
  }

  updateAdminBusiness(businessId: number, newAdminBusiness: AdminBusinessDetail): Observable<any> {
    return this._httpClient.put(`${AppSettings.ADMIN_BUSINESS_URL}/${businessId}`, newAdminBusiness)
  }

  deleteAdminBusiness(businessId: number): Observable<any> {
    return this._httpClient.delete(`${AppSettings.ADMIN_BUSINESS_URL}/${businessId}`)
  }

  resendInvitationBusiness(businessId: number): Observable<any> {
    return this._httpClient.post(`${AppSettings.ADMIN_BUSINESS_URL}/${businessId}/resend-email`, null)
  }

  getBusinessInfo(
    businessId,
    isAuth: boolean = false,
    businessDetail: AdminBusinessDetail | undefined,
    isDropdown: boolean = false
  ) {
    // Use store info if user is not super admin
    let adminPrivilage: any = localStorage.getItem('admin-privilage')
    adminPrivilage = JSON.parse(adminPrivilage)
    if (!adminPrivilage || businessDetail?.storeId != undefined) {
      businessId = businessDetail?.storeId != undefined ? businessDetail.storeId : businessId
      return this._storeRepository.getDetailStore(businessId, isAuth, businessDetail!, isDropdown)
    }

    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))

    return this._httpClient
      .get(
        `${AppSettings.BUSINESS_UTILITIES_URL}/${AppSettings.BUSINESS_UTILITIES_INFO}?${AppSettings.BUSINESS_UTILITIES_ID}=${businessId}`,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => response),
        tap({
          next: (response) => response,
          error: (err: HttpErrorResponse) => {
            if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
          },
        })
      )
  }

  addDetailedCommission(businessId: number, storeId: number, paymentFee: PaymentFee[]): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))
    return this._httpClient
      .put(
        `/api-payment/portal/v1/admin/business-section/${businessId}/${INTEGRATION_PAYMENT_ENDPOINTS.paymentFee.superAdmin}?storeId=${storeId}`,
        {
          fees: paymentFee
        },
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => response),
        tap({
          next: (response) => response,
          error: (err: HttpErrorResponse) => {
            if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
          },
        })
      )
  }

  getMerchantPaymentIntegrationDoku(businessId: number): Observable<Pagination> {
    let url: string = `api/gateway/admin/business-section/${businessId}/doku/FeeSetting`

    return this._httpClient.get<Pagination>(url)
  }

  setDokuFee(businessId: number, data: DokuPaymentPayload[]): Observable<any> {
    let url: string = `/api/gateway/admin/business-section/${businessId}/doku/FeeSetting`

    return this._httpClient.put(url, data)
  }

  getStatusList(): Observable<ResponseModel<Status>> {
    let url: string = '/api/merchant/admin/status/list-status-merchant'

    return this._httpClient.get(url)
  }

  getSetupStatusList(): Observable<ResponseModel<Status>> {
    let url: string = '/api/merchant/admin/status/list-setUp-status'

    return this._httpClient.get(url)
  }
}
