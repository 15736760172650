<div
  class="{{modalContainerClass}} content-vertical-center"
  [style.display]="visible ? 'flex' : 'none'" [ngStyle]="wrapperStyle">
  <div class="c-modal" [ngStyle]="{ width: width }">
    <div class="c-modal-dialog" [ngStyle]="customStyle">
      <div
        [class]="modalContentClass"
        [ngStyle]="{
          'padding-top': padding.top,
          'padding-right': padding.right,
          'padding-bottom': padding.bottom,
          'padding-left': padding.left
        }">
        <div class="c-modal-title">
          <p>{{ title | translate }}</p>
          <img
            *ngIf="showCloseButton"
            src="/assets/images/svg/close-red-frameless.svg"
            alt="X"
            aria-label="Close"
            (click)="close()" />
        </div>
        <div class="c-modal-body">
          <ng-template [ngTemplateOutlet]="modalTemplate"> </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
