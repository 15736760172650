import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class HealthCheckRepositoryService {
  constructor(private _httpClient: HttpClient) {}

  mainBackendService(): Observable<any> {
    return this._httpClient.get('/api/health-check')
  }

  logsBackendService(): Observable<any> {
    return this._httpClient.get('/api-logs/health-check')
  }

  smsBackendService(): Observable<any> {
    return this._httpClient.get('/api-sms/health')
  }

  queBackendService(): Observable<any> {
    return this._httpClient.get('/api-queue/health')
  }

  ideConnectBackendService(): Observable<any> {
    return this._httpClient.get('/apiconnect/health-check')
  }

  commerceBackendService(): Observable<any> {
    return this._httpClient.get('/apicommerce/health-check')
  }

  kioskBackendService(): Observable<any> {
    return this._httpClient.get('/api-kiosk/health-check')
  }

  kdsEdsBackendService(): Observable<any> {
    return this._httpClient.get('/api-kds-eds/health-check')
  }

  dokuBackendService(): Observable<any> {
    return this._httpClient.get('/api/gateway/doku/health-check')
  }

  paymentBackendService(): Observable<any> {
    return this._httpClient.get('/api-payment/health-check')
  }
}
