<app-custom-modal [showCloseButton]="false" [visible]="visible" [fullScreenMode]="true">
  <ng-template>
    <div class="container my-5">
      <div class="row d-flex flex-column justify-content-center">
        <img src="/assets/images/svg/500.svg" alt="" />
        <button class="btn-red-gradient" (click)="reload()">{{ 'Reload' | translate }}</button>
      </div>
    </div>
  </ng-template>
</app-custom-modal>
