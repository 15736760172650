import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Error500Component } from './error-500.component'
import { TranslateModule } from '@ngx-translate/core'
import { CustomModalModule } from '@components/atoms/custom-modal/custom-modal.module'

@NgModule({
  declarations: [Error500Component],
  imports: [CommonModule, TranslateModule, CustomModalModule],
  exports: [Error500Component],
})
export class Error500Module {}
