import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { catchError, map, switchMap, withLatestFrom, of } from 'rxjs'
import * as UserActions from './shared/states/user-info/user-info.action'
import { UserRepository } from '@repository/user-repository/user-repository.service'
import { UserInfo } from '@models/common.model'

@Injectable()
export class UserInfoEffects {
  constructor(
    private actions$: Actions,
    private userRepository: UserRepository,
    private store: Store<{ user: UserInfo }>
  ) {}

  loadUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserInfo),
      withLatestFrom(this.store.pipe(select((state) => state.user))),
      switchMap(([_, user]) => {
        if (user) {
          return of(UserActions.setUserInfo({ user }))
        }
        return this.userRepository.getUserInfo().pipe(
          map((response) => UserActions.setUserInfo({ user: response.data })),
          catchError(() => of(UserActions.deleteUserInfo()))
        )
      })
    )
  )
}
