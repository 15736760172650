import { Injectable } from '@angular/core'
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router'
import { UserRepository } from '@repository/user-repository/user-repository.service'
import { CustomCookieService } from '@services/cookie/cookie.service'
import { setUserActivated } from '@core/utils/auth-stuff'
import { getMonitoredBusiness } from '@core/utils/user-stuff'
import { first, Observable, of, switchMap, tap } from 'rxjs'
import { AdminBusinessDetail } from '@models/admin-business.model'
import { Store, select } from '@ngrx/store'
import { PermissionList } from '@models/permission.model'
import { setAllPermissions } from 'src/app/shared/states/permissions/permission.action'
import { setUserInfo } from 'src/app/shared/states/user-info/user-info.action'

@Injectable({
  providedIn: 'root',
})
export class UserInfoResolver implements Resolve<any> {
  constructor(
    private _store: Store,
    private _userRepository: UserRepository,
    private _cookieService: CustomCookieService,
    private _storeState: Store<PermissionList>,
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const monitoredBusiness: AdminBusinessDetail = getMonitoredBusiness()
    if (!monitoredBusiness?.storeId) {
      return of(false)
    }

    return this._store.pipe(
      select((state: any) => state.user),
      first(),
      switchMap((user) => {
        if (user) {
          this._storeState.dispatch(setAllPermissions(user?.role.permissions as any))
          setUserActivated(this._cookieService, user?.activated || false)
          return of({code: 'OK', status: 200, data: user})
        }
        return this._userRepository.getUserInfo().pipe(
          tap((response: any) => {
            this._store.dispatch(setUserInfo({ user: response?.data }))
            this._storeState.dispatch(setAllPermissions(response.data.role.permissions as any))
            setUserActivated(this._cookieService, response.data?.activated || false)
          })
        )
      })
    )
  }
}
