import { ModuleWithProviders } from '@angular/core'
import { NoPreloading, RouterModule, Routes } from '@angular/router'
import { AppGuard } from '@core/guards/auth.guard'
import { PhoneCodeResolver } from '@core/resolvers/phone-code-resolver/phone-code.resolver'
import { ReloadComponent } from './feature/reload-component/reload-component.component'
import { AppSettings } from '@configs/app.setting'
import { UserInfoResolver } from '@core/resolvers/user-info-resolver/user-info.resolver'
import { DeviceDetectorResolver } from '@core/resolvers/device-detector-resolver/device-detector.resolver'
import { TranslationResolverResolver } from './core/resolvers/translation-resolver/translation-resolver.resolver'
import { StoreInfoResolver } from '@core/resolvers/store-info-resolver/store-info.resolver'

export const routes: Routes = [
  {
    path: '',
    resolve: {
      model: TranslationResolverResolver,
      isBrowser: DeviceDetectorResolver,
      phoneCodeList: PhoneCodeResolver,
    },
    loadChildren: () => import('./core/authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  {
    path: 'admin',
    resolve: { model: TranslationResolverResolver, isBrowser: DeviceDetectorResolver, userInfo: UserInfoResolver },
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AppGuard],
  },
  {
    path: 'fnb',
    resolve: {
      model: TranslationResolverResolver,
      isBrowser: DeviceDetectorResolver,
      userInfo: UserInfoResolver,
      storeInfo: StoreInfoResolver,
    },
    loadChildren: () => import('./feature/fnb.module').then((m) => m.FnbModule),
    canActivate: [AppGuard],
  },
  {
    path: AppSettings.RELOAD_COMPONENT,
    component: ReloadComponent,
  },
  {
    path: 'restricted',
    loadChildren: () => import('./feature/error/feature/error-401/error-401.module').then((m) => m.Error401Module)
  },
  {
    path: '**',
    loadChildren: () => import('./feature/error/feature/error-404/error-404.module').then((m) => m.Error404Module),
    resolve: { model: TranslationResolverResolver, isBrowser: DeviceDetectorResolver },
  },
]

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {
  preloadingStrategy: NoPreloading,
  anchorScrolling: 'enabled',
})
