import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AppSettings } from '@configs/app.setting'
import { API_PUBLIC_LIST } from '@core/utils/api-public'
import { removesLocalstorage } from '@core/utils/app-stuff'
import { COOKIE_NAME_LIST } from '@models/auth.model'
import { Store } from '@ngrx/store'
import { CustomCookieService } from '@services/cookie/cookie.service'
import { ErrorService } from '@services/error/error.service'
import { CookieService } from 'ngx-cookie'
import { NgxSpinnerService } from 'ngx-spinner'
import { Observable, catchError, throwError } from 'rxjs'
import { deleteUserInfo } from 'src/app/shared/states/user-info/user-info.action'
import { environment } from 'src/environments/environment'

@Injectable()
export class HttpResponseErrorInterceptor implements HttpInterceptor {
  constructor(
    protected router: Router,
    private _customCookieService: CustomCookieService,
    private _spinner: NgxSpinnerService,
    private _errorService: ErrorService,
    private _cookieService: CookieService,
    private _store: Store
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token: string =
      this._customCookieService.getItem(environment.production ? AppSettings.AUTH_KEY : AppSettings.AUTH_KEY_DEV) ?? ''

    let headers = {
      Accept: '*/*',
      Language: 'en_US',
    }
    if (!req?.url?.includes('/upload')) {
      headers['Content-Type'] = 'application/json'
    }

    // Check API. If public and there is token, set authorization.
    const isAPIPublic: boolean = API_PUBLIC_LIST.find(
      (list: string) =>
        (list === '/api/utilities/idepos/log/download/' && req.url.includes('/api/utilities/idepos/log/download/')) ||
        (list !== '/api/utilities/idepos/log/download/' && list === req.url)
    )
      ? true
      : false
    if (!isAPIPublic && token) {
      headers['Authorization'] = `Bearer ${token}`
    } else if (!isAPIPublic && !token && req.url.includes('api')) {
      this._cookieService.removeAll()
      COOKIE_NAME_LIST.map((cookieName) => this._customCookieService.removeItem(cookieName))
      removesLocalstorage([AppSettings.TIMEZONE_LIST])
      this._store.dispatch(deleteUserInfo())
      this.router.navigateByUrl('/login')
    }

    req = req.clone({
      url: req.url,
      setHeaders: headers,
    })

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (!this._errorService.isError500$.value) {
          this._errorService.errorRequestHandler(req.url, err.error.status, err.error.error)
        }
        this._spinner.hide()

        return throwError(() => err)
      })
    )
  }
}
