import { Component, ContentChild, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core'
import { fromEvent, Subscription } from 'rxjs'

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss'],
})
export class CustomModuleComponent implements OnInit, OnDestroy {
  @Input() visible!: boolean
  @Input() title: string = ''
  @Input() showCloseButton: boolean = true
  @Input() padding: { top: string; right: string; bottom: string; left: string } = {
    top: '24px',
    right: '28px',
    bottom: '24px',
    left: '28px',
  }
  @Input() width: string = '600px'
  @Input() customStyle!: Object
  @Input() fullScreenMode: boolean = false
  @Input() wrapperStyle!: Object
  @Input() outSideClose: boolean = false
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>()

  @ContentChild(TemplateRef)
  public modalTemplate!: TemplateRef<any>
  outsideCloseSub$!: Subscription

  readonly modalContainerClass: string = 'c-modal-container'
  readonly modalContentClass: string = 'c-modal-content'

  constructor() {}

  ngOnInit(): void {
    this.outSideCloseEvent()
  }

  ngOnDestroy(): void {
    if (this.outsideCloseSub$) {
      this.outsideCloseSub$.unsubscribe()
    }
  }

  close(): void {
    this.visibleChange.emit(false)
  }

  outSideCloseEvent(): void {
    if (!this.outSideClose) return

    this.outsideCloseSub$ = fromEvent(document, 'click').subscribe({
      next: (event) => {
        if (
          event.target &&
          this.visible === true &&
          (<HTMLElement>event.target).closest(`.${this.modalContainerClass}`) &&
          !(<HTMLElement>event.target).closest(`.${this.modalContentClass}`)
        ) {
          this.close()
        }
      }
    })
  }
}
