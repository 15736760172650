import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AppSettings } from '@configs/app.setting'
import { AdminBusinessDetail } from 'src/app/admin/business/ui/admin-business.model'
import { ItemPageConfig } from 'src/app/feature/menu/model/item.model'
import { getAdminPrivilage } from '@core/utils/auth-stuff'
import { getMonitoredBusiness } from '@core/utils/user-stuff'
import { Observable } from 'rxjs'
import { StorePayload } from 'src/app/feature/branch/models/store.model'

@Injectable({
  providedIn: 'root',
})
export class StoreRepositoryService {
  constructor(private _httpClient: HttpClient) {}

  getAllStore(pageConfig: ItemPageConfig): Observable<any> {
    const isAdmin = getAdminPrivilage()
    const monitoredBusiness: AdminBusinessDetail | null = getMonitoredBusiness() || null

    if (isAdmin && monitoredBusiness != null) {
      return this.getMerchantAllStore(pageConfig, monitoredBusiness)
    }

    let url = `${AppSettings.BRANCH_STORE_URL}?`
    for (const config in pageConfig) {
      if (url.charAt(url.length - 1) === '?') {
        url = `${url}${config}=${pageConfig[config]}`
      } else {
        url = `${url}&${config}=${pageConfig[config]}`
      }
    }
    return this._httpClient.get(url)
  }

  deleteStore(id: number): Observable<any> {
    const isAdmin = getAdminPrivilage()
    const monitoredBusiness: AdminBusinessDetail | null = getMonitoredBusiness() || null

    if (isAdmin && monitoredBusiness != null) {
      return this.deleteMerchantStore(id, monitoredBusiness)
    }

    return this._httpClient.delete(`${AppSettings.BRANCH_STORE_URL}/${id}`)
  }

  createStore(data: StorePayload): Observable<any> {
    const isAdmin = getAdminPrivilage()
    const monitoredBusiness: AdminBusinessDetail | null = getMonitoredBusiness() || null

    if (isAdmin && monitoredBusiness != null) {
      return this.createMerchantStore(data, monitoredBusiness)
    }

    return this._httpClient.post(`${AppSettings.BRANCH_STORE_URL}`, data)
  }

  getDetailStore(
    id: number,
    isAuth: boolean = false,
    businessDetail: AdminBusinessDetail | undefined,
    isDropdown: boolean = false
  ): Observable<any> {
    // var monitoredBusiness!: AdminBusinessDetail;

    // if (!isAuth) {
    //   monitoredBusiness = getMonitoredBusiness();

    // }
    const adminPrivilage = getAdminPrivilage()

    if (adminPrivilage && businessDetail != undefined && !isDropdown) {
      return this.getMerchantDetailStore(id, businessDetail)
    }

    if (adminPrivilage && businessDetail != undefined && isDropdown) {
      return this.getMerchantDetailStoreDropdown(id, businessDetail)
    }

    return this._httpClient.get(`${AppSettings.BRANCH_STORE_URL}/${id}`)
  }

  updateStore(data: StorePayload): Observable<any> {
    const isAdmin = getAdminPrivilage()
    const monitoredBusiness: AdminBusinessDetail | null = getMonitoredBusiness() || null

    if (isAdmin && monitoredBusiness != null) {
      return this.updateMerchantStore(data, monitoredBusiness)
    }
    return this._httpClient.put(`${AppSettings.BRANCH_STORE_URL}/${data.id}`, data)
  }

  /** Open ~ Super Admin Business Section */
  getMerchantAllStore(pageConfig: ItemPageConfig, monitoredBusiness: AdminBusinessDetail): Observable<any> {
    let url = `${AppSettings.ADMIN_BUSINESS_SECTION_URL}${monitoredBusiness.id}/${AppSettings.ADMIN_BUSINESS_SECTION_STORE}?`
    for (const config in pageConfig) {
      if (url.charAt(url.length - 1) === '?') {
        url = `${url}${config}=${pageConfig[config]}`
      } else {
        url = `${url}&${config}=${pageConfig[config]}`
      }
    }
    return this._httpClient.get(url)
  }

  deleteMerchantStore(id: number, monitoredBusiness: AdminBusinessDetail): Observable<any> {
    let url = `${AppSettings.ADMIN_BUSINESS_SECTION_URL}${monitoredBusiness.id}/${AppSettings.ADMIN_BUSINESS_SECTION_STORE}/${id}`
    return this._httpClient.delete(url)
  }

  createMerchantStore(data: StorePayload, monitoredBusiness: AdminBusinessDetail): Observable<any> {
    let url = `${AppSettings.ADMIN_BUSINESS_SECTION_URL}${monitoredBusiness.id}/${AppSettings.ADMIN_BUSINESS_SECTION_STORE}`
    return this._httpClient.post(url, data)
  }

  getMerchantDetailStore(id: number, monitoredBusiness: AdminBusinessDetail): Observable<any> {
    let url = `${AppSettings.ADMIN_BUSINESS_SECTION_URL}${monitoredBusiness.id}/${AppSettings.ADMIN_BUSINESS_SECTION_STORE}/${id}`
    // let url = `${AppSettings.BUSINESS_UTILITIES_URL}/${AppSettings.BUSINESS_UTILITIES_INFO}?${AppSettings.BUSINESS_UTILITIES_ID}=${id}`
    return this._httpClient.get(url)
  }

  getMerchantDetailStores(id: number): Observable<any> {
    let url = `${AppSettings.ADMIN_BUSINESS_SECTION_URL}${id}`
    // let url = `${AppSettings.BUSINESS_UTILITIES_URL}/${AppSettings.BUSINESS_UTILITIES_INFO}?${AppSettings.BUSINESS_UTILITIES_ID}=${id}`
    return this._httpClient.get(url)
  }

  getMerchantDetailStoreDropdown(id: number, monitoredBusiness: AdminBusinessDetail): Observable<any> {
    let url = `${AppSettings.ADMIN_BUSINESS_SECTION_URL}${monitoredBusiness.id}/${AppSettings.ADMIN_BUSINESS_SECTION_STORE}/${id}`
    return this._httpClient.get(url)
  }

  updateMerchantStore(data: StorePayload, monitoredBusiness: AdminBusinessDetail): Observable<any> {
    let url = `${AppSettings.ADMIN_BUSINESS_SECTION_URL}${monitoredBusiness.id}/${AppSettings.ADMIN_BUSINESS_SECTION_STORE}/${data.id}`
    return this._httpClient.put(url, data)
  }
  /** Close ~ Super Admin Business Section */
}
