import { Injectable } from '@angular/core'
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router'
import { CommonRepositoryService } from '@repository/common-repository/common-repository.service'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class PhoneCodeResolver implements Resolve<any> {
  constructor(private _commonRepository: CommonRepositoryService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this._commonRepository.phoneCountryCodeList()
  }
}
