import { Action, ActionReducer, createReducer, on } from '@ngrx/store'
import * as UserActions from './user-info.action'
import { UserInfo } from '@models/common.model'

export let initUser!: UserInfo
const userReducer: ActionReducer<UserInfo> = createReducer(
  initUser,
  on(
    UserActions.setUserInfo,
    (state, { user }) => ({ ...state, ...user })
  ),
  on(
    UserActions.deleteUserInfo,
    () => initUser
  )
)

export function reducer(state: UserInfo | undefined, action: Action) {
  return userReducer(state, action)
}
