import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AppSettings } from '@configs/app.setting'
import { Pagination, ReqPagination } from '@core/models/common.model'
import { CustomCookieService } from '@services/cookie/cookie.service'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class BranchRepositoryService {
  constructor(private _httpClient: HttpClient, private _cookieService: CustomCookieService) {}

  branchStoreList(pageConfig: ReqPagination): Observable<Pagination> {
    const authKey = this._cookieService.getItem(
      environment.production ? AppSettings.AUTH_KEY : AppSettings.AUTH_KEY_DEV
    )
    let url: any = `${AppSettings.BRANCH_STORE_URL}?`
    for (const config in pageConfig) {
      if (url.charAt(url.length - 1) === '?') {
        url = `${url}${config}=${pageConfig[config]}`
      } else {
        url = `${url}&${config}=${pageConfig[config]}`
      }
    }
    return this._httpClient.get<Pagination>(url)
  }

  getBranchStoreById(storeId: number): Observable<any> {
    return this._httpClient.get(`${AppSettings.BRANCH_STORE_URL}/${storeId}`)
  }
}
