import { Injectable } from '@angular/core'
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router'
import { AppSettings } from '@configs/app.setting'
import { AuthService } from '@services/auth/auth.service'
import { Observable, of } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AdminAuthResolver implements Resolve<boolean> {
  constructor(private authService: AuthService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const key = route.paramMap.get('key')
    if (typeof key != 'undefined' && typeof key == 'string') {
      this.authService.setAuthKey(key)
      this.authService.email(AppSettings.emailAdmin)
    }

    return of(true)
  }
}
