import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import {
  AfterContentChecked,
  AfterContentInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation,
} from '@angular/core'
import { CommonRepositoryService } from '@repository/common-repository/common-repository.service'
import { setTimezoneList } from '@core/utils/app-stuff'
import { AuthService } from '@services/auth/auth.service'
import { Subscription } from 'rxjs'
import { ErrorService } from '@services/error/error.service'

@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  template: `
    <app-error-500 [(visible)]="showError500"></app-error-500>
    <router-outlet *ngIf="isBrowser"></router-outlet>
    <ngx-spinner type="ball-scale-multiple" color="#73000c" size="medium"></ngx-spinner>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterContentInit, AfterContentChecked, OnDestroy {
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    @Inject(DOCUMENT) private document: any,
    private _commonRepository: CommonRepositoryService,
    private _authService: AuthService,
    private _errorService: ErrorService
  ) {
    this.isBrowser = isPlatformBrowser(platformId)
    this.errorPageListener()
  }

  title = 'ideku'
  deviceInfo: any = null
  isBrowser: boolean = false
  loadLiveChatApi!: Promise<any>
  showError500: boolean = false
  errorPageSubscription: Subscription = new Subscription()

  ngOnInit(): void {
    this.setTimezoneList()
    // this.setLiveChat()
  }

  ngAfterContentInit(): void {}

  ngAfterContentChecked(): void {}

  ngOnDestroy(): void {
    this.errorPageSubscription.unsubscribe()
  }

  errorPageListener(): void {
    this.errorPageSubscription.add(
      this._errorService.isError500$.subscribe({
        next: (val: boolean) => {
          this.showError500 = val
        },
      })
    )
  }

  setTimezoneList() {
    this._commonRepository.timezoneList().subscribe((response) => {
      if (response.status === 200) {
        setTimezoneList(response.data)
      }
    })
  }

  // setScreenView() {
  //   // Check Screen View
  //   const styleToCheck = '(min-width: 800px)'
  //   //Listen to changes in screen width
  //   this.bpo.observe([styleToCheck]).subscribe((result) => {
  //     if (result.matches) {
  //       this.screenView = VIEW_MODE_DESKTOP
  //     } else {
  //       this.screenView = VIEW_MODE_MOBILE
  //     }
  //   })
  // }

  setLiveChat(): void {
    if (this._authService.getAdminPrivilage() === null) {
      const script = document.createElement('script')

      script.type = 'text/javascript'
      script.defer = true
      script.src =
        'https://salesiq.zoho.com/widget?widgetcode=siq292a1d42c7c33bb2a1cb85f6c0e2591158ad06b007517d6516334413dba6f374b801a27b6b25342664d84d3ecdde10f9'
      script.id = 'zsiqscript'

      document.head.appendChild(script)

      let zSiqWidget = document.createElement('div')
      zSiqWidget.setAttribute('id', 'zsiqwidget')
      document.body.appendChild(zSiqWidget)
    }
  }
}
