import { Pipe, PipeTransform } from '@angular/core'
import { currencyWithoutFraction } from '@utils/currency-utils'

@Pipe({
  name: 'idekuNewCurrency',
  standalone: true,
  pure: false,
})
export class IdekuNewCurrencyPipe implements PipeTransform {
  transform(
    value: number | string,
    locale: string = 'en-US',
    currencyId: string,
    maximumFractionDigits: number = 2,
    currencySign: boolean = true
  ): string {
    let result: string = ''
    let stringValue: string = typeof value === 'number' ? value.toString() : value

    if (value === undefined) {
      result = ''
    } else {
      value = Number(stringValue.replace(currencyId, '').replaceAll(',', ''))

      if (currencyWithoutFraction.includes(currencyId)) {
        value = Math.floor(value)
        maximumFractionDigits = 0
      }

      if (!currencySign) {
        result = new Intl.NumberFormat(locale, {
          style: 'currency',
          currency: currencyId,
          maximumFractionDigits,
          currencyDisplay: 'code',
        }).format(value)

        result = result.replace(currencyId, '')
      } else {
        result = new Intl.NumberFormat(locale, {
          style: 'currency',
          currency: currencyId,
          maximumFractionDigits,
          currencyDisplay: 'code',
        }).format(value)
      }
    }

    return result === '' ? '0' : result.trim()
  }
}
