import { IDEKU_PREVIEW_URL } from '@core/models/constant.model'
import { environment } from 'src/environments/environment'

export class AppSettings {
  private static host = 'http://192.168.0.105:8007/file/'

  public static emailAdmin: string = 'admin@ideku.io'

  public static RELOAD_COMPONENT: string = 'reload-component'

  public static URL_MERCHANT: string = '/api/merchant'
  public static URL_MERCHANT_V3: string = '/api/merchant/v3'
  public static URL_MERCHANT_DOWNLOAD: string = `${this.URL_MERCHANT}/download`
  public static TIMEZONE_LIST: string = 'timezone-list'
  public static ALL_STORE: number = 0
  public static APP_VERSION: string = 'app-version'
  public static PAGINATION_LOG_REPORT: string = 'pagination-log-report-list'

  /** Open ~ Sidebar */
  public static CURRENT_MENU_LIST: string = 'current-menu-list'
  public static CURRENT_ADMIN_INIT_MENU_LIST: string = 'current-admin-init-menu-list'
  public static CURRENT_INIT_MENU_LIST: string = 'current-init-menu-list'
  public static BUSINESS_TYPE: string = 'merchant-business-type'

  /** Close ~ Sidebar */

  /** Open ~ Business Url */
  public static ADMIN_BUSINESS_URL: string = `${this.URL_MERCHANT_V3}/admin/business`
  public static BUSINESS_UTILITIES_URL: string = `${this.URL_MERCHANT_V3}/business-utilities`
  public static BUSINESS_UTILITIES_ID: string = 'businessId'
  public static BUSINESS_UTILITIES_INFO: string = 'info'
  public static BUSINESS_UTILITIES_LIST: string = 'list'
  public static ADMIN_BUSINESS_SECTION: string = 'section'
  public static ADMIN_BUSINESS_SECTION_URL: string = `${this.ADMIN_BUSINESS_URL}-${this.ADMIN_BUSINESS_SECTION}/`
  public static ADMIN_BUSINESS_SECTION_MENU_ANALYTIC_URL: string = `/api/merchant/admin/business-${this.ADMIN_BUSINESS_SECTION}/`
  public static ADMIN_BUSINESS_SECTION_DASHBOARD: string = 'dashboard'
  public static ADMIN_BUSINESS_SECTION_BALANCE: string = 'dashboard-balance'
  public static ADMIN_BUSINESS_SECTION_PRODUCT: string = 'product'
  public static ADMIN_BUSINESS_SECTION_ITEM: string = 'item'
  public static ADMIN_BUSINESS_SECTION_STORE: string = 'store'
  public static ADMIN_BUSINESS_SECTION_CATEGORY: string = 'product-category'
  public static ADMIN_BUSINESS_SECTION_MEDIA: string = 'product-media'
  public static ADMIN_BUSINESS_SECTION_ORDER: string = 'order'
  public static ADMIN_BUSINESS_SECTION_CUSTOMER: string = 'customer'
  public static ADMIN_BUSINESS_SECTION_PROMO: string = 'promotion'
  public static ADMIN_BUSINESS_SECTION_COUPON: string = 'coupon'
  public static ADMIN_BUSINESS_SECTION_ANALYTIC: string = 'analytics'
  public static ADMIN_BUSINESS_SECTION_ANALYTIC_ITEM: string = 'item'
  public static ADMIN_BUSINESS_SECTION_ANALYTIC_TRANSACTION: string = 'transaction'
  public static ADMIN_BUSINESS_SECTION_SETTING: string = 'setting'
  public static ADMIN_BUSINESS_SECTION_DELIVERY: string = 'delivery'
  public static ADMIN_BUSINESS_SECTION_CONTACT: string = 'contact'
  public static ADMIN_BUSINESS_SECTION_OPERATION: string = 'operation'
  public static ADMIN_BUSINESS_SECTION_TAX: string = 'tax'
  public static ADMIN_BUSINESS_SECTION_APPEREANCE: string = 'appearance'
  public static ADMIN_BUSINESS_SECTION_STORE_ID: string = 'storeId'
  public static ADMIN_BUSINESS_SECTION_PAYOUT: string = 'payout'
  public static ADMIN_BUSINESS_SECTION_PAYOUT_INIT: string = `${this.ADMIN_BUSINESS_SECTION_PAYOUT}/initialize`
  public static ADMIN_BUSINESS_SECTION_PAYOUT_AUTH: string = `${this.ADMIN_BUSINESS_SECTION_PAYOUT}/step-a`
  public static ADMIN_BUSINESS_SECTION_EMPLOYEE: string = 'employee'
  public static ADMIN_BUSINESS_SECTION_SHIFT: string = `${this.ADMIN_BUSINESS_SECTION_ANALYTIC}-shift`

  // For FnB / Menu Urls
  public static ADMIN_FNB_URL: string = '/api/merchant/admin/business-section'
  public static ADMIN_APPEREANCE_FNB_URL: string = '/api/merchant/admin/business-section'
  public static ADMIN_ITEM_DETAIL_DASHBOARD: string = `${this.ADMIN_BUSINESS_SECTION_ANALYTIC}/item-detail-dashboard`
  public static ADMIN_FNB_ANALYTIC_ORDER: string = `${this.ADMIN_BUSINESS_SECTION_ORDER}-detail`
  public static ADMIN_FNB_ORDER_MENU: string = 'order-menu'

  // IDEPOS
  public static ADMIN_IDEPOS_URL: string = '/api/idepos/admin/business-section'
  public static ADMIN_IDEPOS_ORDER: string = 'order'
  /** Close ~ Business Url */

  /** Open ~ Authentication URL */
  public static USER_LOGIN = '/api/auth/login'
  public static Need2FA = '/api/auth/device/verify'
  public static ADMIN_ID = 'admin-12s23dsk'
  public static FORGOT_PASWORD = '/api/oauth/utilities/forgot-password'
  public static DEVICE_REQUEST = '/api/auth/device/request'
  public static VERIFY_DEVICE = '/api/auth/device/verify'
  public static GET_FILES_BY_USER = ''
  public static GET_FILES_BY_USER_API = ''
  public static GET_FILE_BY_ID_API = ''
  public static MERCHANT_LOGIN = '/api/oauth/login/merchant'
  public static MERCHANT_LOGIN_GOOGLE = '/api/oauth/login/merchant-google'
  public static MERCHANT_REGISTER = '/api/oauth/register/merchant'
  public static MERCHANT_REGISTER_GOOGLE = '/api/oauth/register/merchant-google'
  public static FORGOT_PASSWORD_VERIFY = '/api/oauth/utilities/forgot-password/verify'
  public static CREATE_NEW_PASSWORD = '/api/oauth/utilities/forgot-password/confirm'
  public static VERIFY_MERCHANT = '/api/public/merchant/register/verify'
  public static RESEND_VERIFY_MERCHANT = 'validate-email'
  public static EMAIL_VALIDATED = 'merchant-email-validate'
  public static UPDATE_PASSWORD = 'change-password'
  public static TOKEN_WEB_SOCKET = 'web-socket-token'
  public static OAUTH_ACCOUNT_URL = '/api/oauth-account/info'
  public static INVITATION_URL = '/api/public/ideshop/invitation-verify'
  public static OAUTH_FORGOT_PASSWORD_URL_V2 = '/api/oauth/v2/forgot-password'
  public static FORGOT_PASSWORD_CONFIRM_V2 = 'confirm'
  public static FORGOT_PASSWORD_VERIFY_V2 = 'verify'
  /** Close ~ Authentication URL */

  /** Open ~ Register URL */
  public static REGISTER_FORM = '/api/public/register'
  public static REGISTER_PARTICULARS = '/api/public/particulars'
  public static REGISTER_STORE_INFO = '/api/public/storeInfo'
  /** Close ~ Register URL */

  /** Open ~ Locations URL */
  public static GET_AREA = '/api/area'
  public static GET_COUNTRY = '/api/area/country'
  public static GET_CITY = '/api/area/city'
  public static GET_DISTRICT = '/api/area/district'
  public static GET_PROVINCE = '/api/area/province'
  public static GET_VILLAGE = '/api/area/village'
  /** Close ~ Locations URL */

  /** Open ~ Common Stuff */
  public static phoneCode = 'phoneCode'
  /** Close ~ Common Stuff */

  /** Open ~ Auth stuff */
  public static TOKEN_STORAGE = 'userKey'
  public static USER_KEY = 'userKey'
  public static EMAIL = 'email'
  public static DEVICE_ID = 'deviceId'
  public static DEVICE_KEY = 'deviceKey'
  public static FIRST_NAME_STORAGE = 'firstName'
  public static LAST_NAME_STORAGE = 'lastName'
  public static MERCHANT_ID = 'merchantId'
  public static ROLE = 'role'
  public static AUTH_KEY_DEV = `authToken-dev-${environment.domain}`
  public static AUTH_KEY_UAT = `authToken-uat-${environment.domain}`
  public static AUTH_KEY = `authToken-prod-${environment.domain}`
  public static AUTH_VALIDATED = 'authValidated'
  public static AUTH_VALIDATE_COUNTER = 'authValidateCounter'
  public static BUSINESS_NAME = 'businessName'
  public static NAME_INITIAL = 'nameInitial'
  public static TEMPLATE = 'template'
  public static NEW_USER = 'newMerchant'
  public static ONBOARD_ACCESS = 'onboardAccess'
  public static EMPTY_PASSWORD = 'emptyPassword'
  public static MERCHANT_ASK_GENERAL_INFO = 'askMerchantBasicIdentity'
  public static MERCHANT_ASK_GENERAL_INFO_LOGIN = 'askMerchantBasicIdentityonLogin'
  public static MERCHANT_LAST_LOGIN = 'merchantLastLogin'
  public static USER_INFO = 'userInfo-prod'
  public static USER_INFO_DEV = 'userInfo-dev'
  public static ROLE_NAME = 'user-role-name'
  public static COOKIE_LANGUAGE = '__language'
  public static COOKIE_CURRENCY = 'currency'
  public static COOKIE_CURRENCY_LABEL = 'currencyLabel'
  public static COOKIE_COUNTRY = 'country'
  public static COOKIE_CURRENCY_SYMBOL = 'currency-symbol'
  public static COOKIE_USER_ACTIVATED = 'user-activated'
  public static MERCHANT_TYPE = 'merchant-type'
  public static TIMEZONE_OFFSET = 'timezone-offset'

  // Super Admin Auth Stuff
  public static COOKIE_SA_CURRENCY = 'sa-currency'
  public static COOKIE_SA_CURRENCY_LABEL = 'sa-currencyLabel'
  public static COOKIE_SA_COUNTRY = 'sa-country'
  public static COOKIE_SA_CURRENCY_SYMBOL = 'sa-currency-symbol'
  public static LOCALSTORAGE_SA_TIMEZONE_OFFSET = 'sa-timezone-offset'
  public static ADMIN_PRIVILAGE = 'admin-privilage'
  /** Close ~ Auth stuff */

  /** Open ~ Categories URL */
  public static PRODUCT_CATEGORY_URL = '/api/merchant/category'
  public static PRODUCT_CATEGORY_BY_MERCHANT = '/api/merchant/category/dropdown'
  public static PRODUCT_CATEGORY_BY_MERCHANT_CHANNEL = '/api/merchant/category/merchant-channel'
  /** Close ~ Categories URL */

  /** Open ~ Categories URL */
  public static PRODUCT_CATEGORY_URL_V3 = `.${this.URL_MERCHANT_V3}/product-category`
  /** Close ~ Categories URL */

  /** Open ~ Catalog URL */
  public static PRODUCT_CATALOG_URL = '/api/merchant/product-catalog'
  public static PRODUCT_CATALOG_DROPDOWN_URL = '/api/merchant/product-catalog/dropdown'
  public static PRODUCT_CATALOG_DROPDOWN_BY_MERCHANT_URL = '/api/merchant/product-catalog/dropdown-list'
  /** Close ~ Catalog URL */

  /** Open ~ File URL */
  public static UPLOAD_IMAGE = '/api/upload/compress?prefix='
  // public static UPLOAD_PRODUCT_IMAGE = `${environment.apiUrl}/merchant/upload`
  public static UPLOAD_PRODUCT_IMAGE = `/api/merchant/upload`
  public static SHOW_PRODUCT_IMAGE = '/api/files/upload'
  // public static DESCRIPTION_IMG_PREVIEW = `${environment.apiUrl}/api/images-preview`
  public static DESCRIPTION_IMG_PREVIEW = `/api/images-preview`
  public static DESCRIPTION_IMG_CDN = 'https://cdn.ideku.id/images/full/product/'
  public static UPLOAD_PRODUCT_CSV = 'https://cdn.ideku.id/data-templates/template-import-csv.csv/:/$download'
  /** Close ~ File URL */

  /** Open ~ File Stuf */
  public static UPLOAD_IMG_PRODUCT = 'product'
  public static UPLOAD_IMG_VARIANT = 'product-variant'
  public static UPLOAD_IMG_BUSINESS_LOGO = 'business-logo'
  public static UPLOAD_IMG_BANNER_DESKTOP = 'banner-desktop'
  public static UPLOAD_IMG_BANNER_MOBILE = 'banner-mobile'
  public static UPLOAD_IMG_BACKGROUND = 'custom-themes-background'
  public static DOWNLOAD_ERROR_CSV = 'd-error-csv'

  /** Close ~ File Stuf */

  /** Open ~ Product Stuff */
  public static MERCHANT_PRODUCT = '/api/merchant/product'
  public static ADD_PRODUCT = '/api/merchant/product'
  public static FIND_ALL_PRODUCT = '/api/merchant/product'
  public static SEARCH_PRODUCT = '/api/merchant/product/product'
  public static SEARCH_PRODUCT_CATEGORY = '/api/merchant/category/category/{name}'
  public static PRODUCT_BY_ID = '/api/merchant/product'
  public static SEQUENCE_CHECK = '/api/merchant/product/sequence-check'
  public static DELETE_PRODUCT = '/api/merchant/product'
  public static UPDATE_PRODUCT = '/api/merchant/product'
  public static TOTAL_PRODUCT = '/api/merchant/dashboard'
  public static SALDO = '/api/merchant/dashboard/balance'
  public static PRODUCT_TAG_LIST = 'api/merchant/product-catalog/list-tags'
  public static PRODUCT_DOWNLOAD_REQUEST_URL = '/api/merchant/product-download/add-queue'

  // V3
  public static MERCHANT_PRODUCT_V3 = `${this.URL_MERCHANT_V3}/product`
  public static PROMO_PRODUCT_LIST = 'list'
  /** Close ~ Product URL */

  /** Open ~ Job Queue */
  public static JOB_QUEUE_URL = '/api/job-queue'
  public static JOB_QUEUE_CHECKING = 'c'
  public static JOB_QUEUE_DOWNLOAD = 'd'
  /** Close ~ Job Queue */

  /** Open ~ Product Media */
  public static PRODUCT_MEDIA_URL = '/api/merchant/product-image-video'
  public static PRODUCT_MEDIA_DOWNLOAD = 'download'
  public static PRODUCT_UPLOAD = 'product-upload'
  public static PRODUCT_UPLOAD_URL = `${environment.apiUrl}/merchant/product-upload`
  public static PRODUCT_UPLOAD_ADD = 'add-queue'
  public static PRODUCT_UPLOAD_UPDATE = 'update-queue'

  // V3
  public static PRODUCT_MEDIA_V3_URL = `${this.URL_MERCHANT_V3}/product-media`
  /** Close ~ Product Media */

  /** Open ~ Merchant URL */
  public static MERCHANT_SETTINGS = '/api/merchant/setting'
  public static MERCHANT_TEMPLATE = '/api/public/template/general'
  public static MERCHANT_ADDRESS_URL = '/api/merchant/address/dropdown'

  // V3
  public static MERCHANT_SETTINGS_V3 = `${this.URL_MERCHANT_V3}/setting`
  public static MERCHANT_DELIVERY = 'delivery'
  public static MERCHANT_CONTACT = 'contact'
  public static MERCHANT_OPERATION = 'operation'
  public static MERCHANT_TAX = 'tax'
  /** Close ~ Merchant URL */

  /** Open ~ Merchant URL */
  public static MERCHANT_SETTINGS_V2 = '/api/merchant/setting-v2'
  public static MERCHANT_SETTINGS_V2_SUBDOMAIN = '/api/merchant/setting-v2/subdomain'
  public static PATH_DOMAIN = 'domain-path'
  /** Close ~ Merchant URL */

  /** Open ~ Payment URL */
  public static PAYMENT_URL = '/api/merchant/payment'
  public static PAYMENT_METHOD = this.PAYMENT_URL
  public static PAYMENT_TYPE = `${this.PAYMENT_URL}/type`
  public static PAYMENT_BANK_LINK = 'integration-payments'
  public static PAYMENT_ROUNDING = 'rounding'
  /** Close ~ Payment URL */

  /** Open ~ Channel URL */
  public static CHANNEL_URL = '/api/merchant/sales-channel'
  public static CHANNEL_URL_V2 = '/api/merchant/v2/sales-channel'
  public static CHANNEL_MERCHANT_SLUG = 'merchant-url'
  public static CHANNEL_LINK_URL = '/api/merchant/sales-channel/tautan-dropdown'
  public static CHANNEL_LINK_CONTACT = '/api/merchant/sales-channel/dropdown-contact-setting'
  public static CHANNEL_BACKGROUND_LIST = '/background-landing-page'
  public static CHANNEL_PREVIEW = '/preview'
  public static CHANNEL_CHECK_URL = '/subdomain'
  public static CHANNEL_WEBSOCKET = 'https://api.ideku.id/ws'
  /** Close ~ Channel URL */

  /** Open ~ Channel Stuff */
  public static DEFAULT_CHANNEL_NAME = 'DEFAULT'
  /** Close ~ Channel Stuff */

  /** Open ~ Order */
  // URLS
  public static ORDER_URL = '/api/merchant/orders'
  public static ORDER_STATUS_URL = '/api/merchant/orders/status-dropdown'
  public static MERCHANT_SLUG = 'merchant-url'
  public static WA_MSG_RECAP = 'recap-message'

  // V2
  public static ORDER_URL_V2 = '/api/idepos/merchant/v2/order'

  // V3
  public static ORDER_URL_V3 = `${this.URL_MERCHANT_V3}/order`
  public static ORDER_FNB_URL_V3 = '/api/idepos/merchant/v3'

  // Order Stuff
  public static ORDER_UTILITIES = 'order-utilities'
  public static ORDER_LIST_STATUS = 'list-status'
  public static ORDER_LIST_TYPE = 'list-type'
  public static ORDER_FNB_MENU = 'menu'
  public static ORDER_PAYMENT_RECONCILIATION = 'pr'
  public static ORDER_LIST_REFUND_TYPE = 'list-refund-type'
  public static ORDER_EXPORT = '/api/merchant/download'
  public static ORDER_EXPORT_SUPER_ADMIN = '/api/merchant/download/admin/business-section'
  /** Close ~ Order */

  /** Open ~ Customer */
  public static CUSTOMER_URL = '/api/merchant/customer'
  public static CUSTOMER_TYPE_LIST = 'list-type'
  public static CUSTOMER_ORDER = 'order'
  public static CUSTOMER_DETAIL = 'detail'

  // V3
  public static CUSTOMER_URL_V3 = `${this.URL_MERCHANT_V3}/customer`

  // Idepos
  public static IDEPOS_CUSTOMER_URL = '/api/idepos/customer'
  /** Close ~ Customer */

  /** Open ~ Transaction */
  public static TRANSACTION_URL = '/api/merchant/transaction'
  public static TRANSACTION_STATUS_URL = '/api/merchant/transaction/type-dropdown'
  /** Close ~ Transaction */

  /** Open ~ Expedition URL */
  public static EXPEDITION_URL = '/api/merchant/currier-expedition'
  /** Close ~ Expedition URL */

  /** Open ~ Utilities URL */
  public static MERCHANT_UTILITIES = '/api/merchant/utilities'
  public static PRODUCT_UTILITIES = '/api/merchant/product-utilities'

  // V3
  public static MERCHANT_UTILITIES_URL_V3 = `${this.URL_MERCHANT_V3}/utilities`
  public static MERCHANT_UTILITIES_UPADTE_PASSWORD = 'update-password'
  /** Close ~ Utilities URL */

  /** Open ~ Product Utilities stuff */
  public static PRODUCT_VARIANTS = '/promotion-product-variants'
  /** Close ~ Product Utilities stuff */

  /** Open ~ Analytic URL */

  // * URLS
  public static SUPER_ADMIN_ANALYTIC_URL = '/api/merchant/admin/business-section'
  public static IDEPOS_ANALYTIC = '/api/merchant'
  public static MERCHANT_ANALYTIC = '/api/merchant/analytics'
  public static MERCHANT_ANALYTIC_CHANNEL_LIST = '/api/merchant/analytics/channel-list'
  public static MERCHANT_ANALYTIC_DROPDOWN_CONTACT_SETTING = '/api/merchant/analytics/dropdown-contact-setting'
  public static MERCHANT_ANALYTIC_TYPE = '/api/merchant/analytics/type'
  public static IDEPOS_ANALYTIC_ORDER = 'analytics/order'
  public static IDEPOS_ANALYTIC_ORDER_DETAIL = 'analytics/order-detail'
  public static IDEPOS_ANALYTIC_ITEM = 'analytics/item'
  public static IDEPOS_ANALYTIC_CATEGORY_SUMMARY = 'analytics/get-analytics-category-summary'
  public static IDEPOS_ANALYTIC_ITEM_DETAIL = 'analytics/item-detail'
  public static IDEPOS_ANALYTIC_CATEGORY_DETAIL = 'analytics/get-analytics-category-detail'
  public static IDEPOS_DOWNLOAD_REQ_URL: string = '/api/merchant/analytics/download'
  public static IDEPOS_ANALYTIC_ITEM_DOWNLOAD: string = 'analytics-items'
  public static IDEPOS_ANALYTIC_ORDER_DOWNLOAD: string = 'analytics-order'

  // * URLS V2
  public static ANALYTIC_URL_V2 = '/api/merchant/analytics-v2'
  public static ANALYTIC_TRANSACTION = 'transaction'
  public static ANALYTIC_PRODUCT = 'product'
  public static ANALYTIC_ORDER_DETAIL_V2 = 'order-detail'

  // * URLS V3
  public static ANALYTIC_URL_V3 = `${this.URL_MERCHANT_V3}/analytics`

  // Analytic Shift
  public static ANALYTIC_SHIFT = '-shift'

  // Analytic Payment Recon
  public static ANALYTIC_PR = `${this.URL_MERCHANT}/order-menu/pr`
  public static ANALYTIC_PR_V3 = `${this.URL_MERCHANT_V3}/order-menu/pr`
  public static ANALYTIC_PR_DETAIL = `${this.ANALYTIC_PR_V3}-detail`
  public static ANALYTIC_PR_EXPORT = `${this.URL_MERCHANT_DOWNLOAD}/order/export-pr`

  // Analytic Promotions
  public static ANALYTIC_PROMOTION = `${this.ANALYTIC_URL_V3}/promotions`
  public static ANALYTIC_PROMOTION_EXPORT = `${this.URL_MERCHANT_DOWNLOAD}/analytic-promotion`

  // * URLS V3 ADMIN
  public static ANALYTIC_ADMIN_PR = `order-menu/pr`
  public static ANALYTIC_ADMIN_PR_DETAIL = `${this.ANALYTIC_ADMIN_PR}-detail`
  public static ANALYTIC_ADMIN_PR_EXPORT = `${this.ANALYTIC_ADMIN_PR}-export`
  public static ANALYTIC_ADMIN_PROMOTION = `analytics-promotion`

  /** Close ~ Analytic URL */

  public static CART_ID_STORAGE = 'cartId'

  public static GET_FILE_BY_PATH_API = ''

  public static NO_IMAGE_API = '/assets/img/app/no-image.png'

  public static LEFT_KEY = 37

  public static RIGHT_KEY = 39

  public static ENTER_KEY = 13

  public static DOWN_KEY = 40

  public static GET_IMAGE_BY_ID = '/apifile/file/default/id'
  public static GET_IMAGE_BY_TOKEN = '/apifile/file/default/id?id='
  public static DOWNLOAD_FILE = '/apifile/file/default/download?id='
  public static DELETE_FILE = '/apifile/file/default/delete'
  public static GET_USER_IMAGE = '/apifile/file/default/get-by-user'
  public static GET_IMAGE_PATH = '/apifile/file/default/index?path='
  // public static UPLOAD_IMAGE = '/apifile';

  public static USER_LIST = '/api/admins'
  public static CURRENCY_LIST = '/api/currency/'
  public static CURRENCY_REWARD = '/api/currency/reward/'
  public static CURRENCY_PREFERENCE = '/api/currency/preferred/'

  public static CURRENCY_GROUP_LIST = '/api/currency/group/'

  public static GOOGLE_CLIENT_ID = '516459936749-8imt4t174q6dgv2h4518uuo8ca6r58ve.apps.googleusercontent.com' //Temp

  /** Open ~ Image maximum file size */
  public static maximumImageSizeDefault: number = 3000000 // equal to 3MB
  public static maximumImageSizeProduct: number = 5000000 // equal to 5MB
  public static maximumImageSizeLogo: number = 3000000 // equal to 3MB
  public static maximumImageSizeBanner: number = 10000000 // equal to 10MB
  public static minimumFnBMobileBannerWidth: number = 414
  public static minimumFnBMobileBannerHeight: number = 232
  public static aspectRatioFnBLeftBanner: number = 207
  public static aspectRatioFnBRightBanner: number = 116
  /** Close ~ Image maximum file size */

  /** Open ~ Contents stuff */
  public static CONTENT_URL: string = '/api/merchant/content/announcement'
  /** Close ~ Contents stuff */

  /** Open ~ Appereance stuff */
  public static APPEARANCE_URL: string = `.${this.URL_MERCHANT_V3}/appearance`
  public static APPEREANCE_FNB_URL: string = '/api/merchant/appearance'
  /** Close ~ Appereance stuff */

  /** Open ~ Common URLs */
  public static CONNECTION_TEST_URL: string = '/api/health-check'
  /** Close ~ Common URLs */

  /** Open ~ Withdraw */
  public static WITHDRAW_PAYOUT = '/api/merchant/dashboard/payout'
  public static WITHDRAW_PAYOUT_VERIFICATION = '/api/merchant/dashboard/payout/verification'
  /** Close ~ Contents stuff */

  /** Open ~ Integration */
  // URLS
  public static INTEGRATION_URL = '/api/merchant/integration'

  // Integration Shopee
  public static INTEGRATION_SHOPEE = 'shopee/auth'
  public static SHOPEE_GET_URL = 'get-url'
  public static SHOPEE_VERIFY = 'verify-auth'

  // V3
  public static INTEGRATION_URL_V3 = `.${this.URL_MERCHANT_V3}/integration`
  public static INTEGRATION_PAYMENT = 'payment'
  public static INTEGRATION_ACCOUNTING = 'accounting'
  public static INTEGRATION_SHIPPING = 'shipping'
  public static INTEGRATION_TYPE = 'integrationType'
  /** Close ~ Integration */

  /** Open ~ Promotion */
  // URLS
  public static PROMO_URL = '/api/merchant/promotion'
  public static CRITERIA_LIST = 'list-criteria'
  public static PRESENT_LIST = 'list-present'
  public static TYPE_LIST = 'list-type'

  // V2
  // public static PROMO_URL_V2 = '/api/merchant/promotion-v2'
  // public static PROMO_PRODUCT_LIST = 'product-list'

  // V3
  public static PROMO_URL_V3 = `.${this.URL_MERCHANT_V3}/promotion`
  public static PROMO_PRODUCT_V3 = '/api/merchant/v3'
  public static PROMO_PRODUCT_LIST_V3 = 'product/list'
  public static ADMIN_MODE = 'admin/business-section/'
  /** Close ~ Promotion */

  /** Open ~ Coupon URL */
  public static COUPON_URL = '/api/merchant/coupon'
  public static COUPON_DROPDOWN = 'dropdown'

  // Coupon URL v2
  public static COUPON_URL_V2 = '/api/merchant/coupon-v2'
  public static COUPON_USED_TYPE = 'list-coupon-used'

  // Coupon URL v3
  public static COUPON_URL_V3 = `.${this.URL_MERCHANT_V3}/coupon`
  /** Close ~ Coupon URL */

  /** Open ~ Domain Stuff */
  public static DOMAIN_URL = '/api/merchant/domain'
  public static DOMAIN_ACTIVE = 'dropdown'
  /** Close ~ Domain Stuff */

  /** Open ~ Onboard Url */
  public static ONBOARD_URL = '/api/merchant/merchant-setup'
  public static ONBOARD_CHANNEL_URL = 'step-1'
  public static ONBOARD_CHANNEL_URL_V2 = 'step-1-v2'
  public static ONBOARD_PRODUCT_URL = 'step-2'
  public static ONBOARD_GENERAL_INFO_URL = 'step-3'
  /** Close ~ Onboard Url */

  /** Open ~ Preview Url */
  public static PREVIEW_LANDING_PAGE = `https://${IDEKU_PREVIEW_URL}landing-preview?token=`
  public static PREVIEW_ECOMMERCE = `https://${IDEKU_PREVIEW_URL}commerce-preview?token=`
  public static DEFAULT_BANNER = 'https://cdn.ideku.id/assets/themes/banner/default-banner.png'
  public static IDEFOOD_LOGIN_BG_1 =
    'https://cdn.ideku.id/images/full/custom-themes-background/d37e79ec-9b68-4264-a065-7643af12b026.png'
  public static IDEFOOD_LOGIN_BG_2 =
    'https://cdn.ideku.id/images/full/custom-themes-background/0d5d4fa3-6d27-4e91-935b-9e5c1c405e1b.png'
  public static IDEFOOD_LOGIN_BG_3 =
    'https://cdn.ideku.id/images/full/custom-themes-background/e29ca840-b7ee-4553-bd85-4fe35bc27ceb.png'
  // public static PREVIEW_ = "";
  public static PREVIEW_EC_KEY = 'PREVIEW_STATE_READY_COMMERCE'
  public static PREVIEW_LP_KEY = 'PREVIEW_STATE_READY_LANDING'
  /** Close ~ Preview Url */

  // Min & Max Date
  public static MIN_DATE = '2000-01-01'
  public static MAX_DATE = '2100-01-01'

  // Admin stuff
  public static ADMIN_AUTH = 'admin-auth-key'

  /** Open ~ Item List  */
  // public static ITEM = '/api/idepos/item'
  public static ITEM_LIST_URL = '/api/merchant/v3-1/item'
  // public static ITEM_BY_ID = '/api/idepos/homepage-v2/product'
  public static PRODUCT_ITEM_BY_ID = '/api/merchant/product'
  public static ALL_IMAGES = '/api/merchant/product-image-video'
  public static ITEM_MEDIA = '/api/merchant/upload'
  public static STORES = '/api/idepos/store'
  /** Close ~ Item List  */

  /** Open ~ ROLE URLs */
  // Urls Super Admin Permission
  public static ROLE_URL = `${this.URL_MERCHANT_V3}/roles`
  // Urls Admin Permission
  public static ROLE_MERCHANT_URL = `${this.URL_MERCHANT_V3}/admin/roles`

  // Roles Stuff
  public static ROLE_PERMISSION = 'permissions'
  public static ROLE_MENU_PATH = 'role'

  // Urls Super Admin Role User
  public static ROLE_USER_URL = `${this.URL_MERCHANT_V3}/admin/users`

  // Urls Admin Role User
  public static ROLE_USER_MERCHANT_URL = `${this.URL_MERCHANT_V3}/user`

  /** Close ~ ROLE URLs */

  /** Open ~ Custom Menu */
  public static CUSTOM_MENU_SUPER_ADMIN = '/api/merchant/admin/business-section'
  public static CUSTOM_MENU_ADMIN = '/api/merchant/v3-1'
  public static CUSTOM_MENU_URL = 'custom-menu'
  public static ITEM_CUSTOM_MENU_URL = 'custom-menu/list-item'
  public static GET_CUSTOM_MENU_URL = 'custom-menu/list-menu'
  /** Close ~ Custom Menu */

  /** Open ~ Menu-V2 */
  public static MENU_MENU_V2: string = 'menu-v2'
  public static PRODUCT_MODIFIER_MASTER_V2: string = 'modifier-master'
  public static PRODUCT_ITEM_MENU_V2: string = 'item'
  public static CUSTOM_MENU_URL_V2: string = 'custom-menu'
  public static PRODUCT_CATEGORY_MENU_V2: string = 'category'
  /** Close ~ Menu-V2 */

  /** Open ~ Authentication Routes */
  public static LOGIN_AUTH: string = 'login'
  public static REGISTER_AUTH: string = 'register'
  /** Close ~ Authentication Routes */

  /** Open ~ Pages Routes */
  public static PAGES_ROUTE: string = 'pages'
  public static FNB_ROUTE: string = 'fnb'
  public static RETAIL_ROUTE: string = 'retail'
  public static ADMIN_ROUTE: string = 'admin'
  public static PASSWORD_UPDATE_MENU: string = 'update-password'
  public static PASSWORD_CREATE_MENU: string = 'create-password'
  public static ONBOARDING_MENU: string = 'onboarding'
  public static SETTINGS_MENU: string = 'settings'
  public static SETTINGS_GENERAL_MENU: string = 'general'
  public static SETTINGS_BUSINESS_MENU: string = 'business'
  public static SETTINGS_ORDER_STATUS_MENU: string = 'oder-status'
  public static SETTINGS_CHANNEL_MENU: string = 'channel'
  public static SETTINGS_DOMAIN_MENU: string = 'domain'
  public static SETTINGS_DELIVERY_MENU: string = 'delivery'
  public static SETTINGS_ADDRESS_MENU: string = 'address'
  public static SETTINGS_CONTACT_MENU: string = 'contact'
  public static SETTINGS_OPERATIONAL_MENU: string = 'operational'
  public static SETTINGS_SCHEDULED_ORDER: string = 'scheduled-order'
  public static SETTINGS_RECEIPT_KITCHEN_TEMPLATE: string = 'receipt-kitchen-template'
  public static SETTINGS_TAX_MENU: string = 'additional-fee'
  public static SETTINGS_TIPPING_MENU: string = 'tipping'
  public static PRODUCT_MENU: string = 'products'
  public static PRODUCT_ITEM_MENU: string = 'item'
  public static PRODUCT_ITEM_SYNC_HISTORY_MENU: string = 'sync-history'
  public static PRODUCT_ITEM_LIST: string = 'item-list'
  public static PRODUCT_DUPLICATE_SKU_MENU: string = 'duplicate-sku'
  public static PRODUCT_CATEGORY_MENU: string = 'category'
  public static PRODUCT_IMPORT_MENU: string = 'import'
  public static PRODUCT_MEDIA_MENU: string = 'media'
  public static PRODUCT_CUSTOM_MENU: string = 'custom-menu'
  public static BUSINESS_MENU: string = 'business'
  public static BUSINESS_CREATE_MENU: string = 'add'
  public static ROLE_MENU: string = 'role'
  public static ROLE_USER_MENU: string = 'user'
  public static ROLE_MANAGEMENT_MENU: string = 'management'
  public static ORDER_MENU: string = 'orders'
  public static CUSTOMER_MENU: string = 'customer'
  public static TRANSACTION_MENU: string = 'transaction'
  public static ANALYTIC_MENU: string = 'analytics'
  public static INGREDIENT_MENU: string = 'ingredient'
  public static INGREDIENT_LIST_MENU: string = 'list'
  public static INGREDIENT_CATEGORY_MENU: string = 'category'
  public static INGREDIENT_RECIPE_MENU: string = 'recipe'
  public static INVENTORY_MENU: string = 'inventory'
  public static INVENTORY_STOCK_CARD_MENU: string = 'stock-card'
  public static INVENTORY_SUPPLIER_MENU: string = 'supplier'
  public static INVENTORY_PURCHASE_MENU: string = 'purchase'
  public static INVENTORY_MANAGE_STOCK_MENU: string = 'manage-stock'
  public static INVENTORY_TRANSFER_STOCK_MENU: string = 'transfer-stock'
  public static INVENTORY_STOCK_IN_MENU: string = 'stock-in'
  public static INVENTORY_STOCK_OUT_MENU: string = 'stock-out'
  public static INVENTORY_ADJUSTMENT_MENU: string = 'adjustment'
  public static INGREDIENT_UNIT_MENU: string = 'unit'
  public static ANALYTIC_TRANSACTION_MENU: string = 'transaction'
  public static ANALYTIC_PRODUCT_MENU: string = 'product'
  public static ANALYTIC_ORDER_MENU: string = 'order'
  public static ANALYTIC_ITEM_MENU: string = 'item'
  public static ANALYTIC_CATEGORY_MENU: string = 'category'
  public static ANALYTIC_SHIFT_MENU: string = 'shift'
  public static ANALYTIC_PAYMENT_RECONCILIATION_MENU: string = 'payment-reconciliation'
  public static ANALYTIC_PROMOTIONS_MENU: string = 'promotions'
  public static DASHBOARD_MENU: string = 'dashboard'
  public static DASHBOARD_MENU_TYPE_RETAIL: string = 'retail'
  public static DASHBOARD_MENU_TYPE_MENU: string = 'menu'
  public static DASHBOARD_MENU_TYPE_ADMIN: string = 'admin'
  public static DASHBOARD_MENU_TYPE_GENERAL: string = 'general'
  public static DASHBOARD_MENU_TYPE_STAFF: string = 'staff'
  public static INTEGRATION_MENU: string = 'integration'
  public static INTEGRATION_PAYMENT_MENU: string = 'payment'
  public static INTEGRATION_ACCOUNTING_MENU: string = 'accounting'
  public static INTEGRATION_PAYMENT_INDONESIA_MENU: string = '-id'
  public static INTEGRATION_DELIVERY_MENU: string = 'delivery'
  public static INTEGRATION_SHIPPING_MENU: string = 'shipping'
  public static PROMO_MENU: string = 'promotions'
  public static PROMO_DETAIL_MENU: string = 'promo'
  public static PROMO_DETAIL_MENU_MENU: string = 'menu'
  public static PROMO_COUPON_MENU: string = 'coupon'
  public static PROMO_COUPON_MENU_MENU: string = 'menu'
  public static APPEREANCE_MENU: string = 'appearance'
  public static APPEREANCE_SETTING_MENU: string = 'setting'
  public static APPEREANCE_FNB_MENU: string = 'theme'
  public static KDS_MENU: string = 'kds'
  public static KDS_GENERAL_MENU: string = 'general'
  public static KDS_DISPLAY_MENU: string = 'display'
  public static KDS_TICKET_MENU: string = 'ticket'
  public static BRANCH_MENU: string = 'branch'
  public static BRANCH_STORE_MENU: string = 'store'
  public static BRANCH_POS_DEVICE_MENU: string = 'pos-device'
  public static COMMON_ADD_MENU: string = 'add'
  public static COMMON_EDIT_MENU: string = 'edit'
  public static COMMON_ID_MENU: string = 'id'
  public static COMMON_KEY_MENU: string = 'key'
  public static COMMMON_DUPLICATE_MENU: string = 'duplicate'
  public static PAGE_NOT_FOUND: string = 'page-not-found'
  public static ORDER_RETAIL_MENU: string = 'retail'
  public static ORDER_MENU_MENU: string = 'menu'
  public static COMMON_MENU: string = 'menu'
  public static EMPLOYEE_MENU: string = 'employee'
  public static SETTINGS_ROUNDING_MENU: string = 'payment-rounding'
  public static TABLE_MANAGEMENT_MENU: string = 'table'
  public static TABLE_QR_GENERATOR_MENU: string = 'qr-generator'
  public static MENU_MENU: string = 'menu'
  public static TABLE_TABLE_SETUP: string = 'table-setup'
  public static MERCHANT_LOG: string = 'merchant-log'
  public static LOG_REPORT_MENU: string = 'log-report'
  public static CUSTOMER_DISPLAY_MENU: string = 'customer-display'
  public static QUEUE_NUMBER_DISPLAY_MENU: string = 'queue-number-display'
  public static SETTING_CASH_DRAWER_MENU: string = 'cash-drawer'
  public static PERFORMANCE_MENU: string = 'performance'
  public static PERFORMANCE_DETAIL_MENU: string = 'detail'
  public static IDECONNECT_MENU: string = 'ideconnect'
  public static BANK_ACCOUNT_MENU: string = 'bank-account'
  public static IDEQUEUE_MENU: string = 'idequeue'
  public static IDEQUEUE_APPEARANCE_MENU: string = 'appearance'
  public static IDEQUEUE_SETTINGS_MENU: string = 'settings'
  public static IDEQUEUE_QR_MENU: string = 'qr'
  public static NPS_REPORT_MENU: string = 'nps-report'
  public static PAYMENT_INTEGRATION_KPAY_MENU: string = 'kpay'
  public static PAYMENT_INTEGRATION_GDC_MENU: string = 'gdc'
  public static HARDWARE_MENU: string = 'hardware'
  public static KIOSK_MENU: string = 'idekiosk'
  public static KIOSK_APPEARANCE_MENU: string = 'appearance'
  public static KIOSK_SETTINGS_MENU: string = 'settings'
  public static CLOUD_PRINTER: string = 'cloud-printer'
  /** Close ~ Pages Routes */

  /** Open Common Aliases */

  // Filter
  public static STATUS_DELETED: number = 0
  public static STATUS_ACTIVE: number = 1
  public static STATUS_INACTIVE: number = 2
  public static STATUS_INVITED: number = 8
  public static STATUS_SUSPENDED: number = 9
  public static STATUS_SUCCESS: number = 11
  public static STATUS_FAILURE: number = 12
  public static STATUS_ACCEPTED: number = 21
  public static STATUS_REJECTED: number = 22
  public static STATUS_EXPIRED: number = 23
  public static STATUS_PENDING: number = 24
  public static STATUS_NEED_ACTIVATION: number = 30
  public static STATUS_COMPLETE: number = 35
  public static STATUS_IN_COMPLETE: number = 38
  public static STATUS_ALL: number = 99
  public static FILTER_ALL: string = 'ALL'
  public static FILTER_CODE: string = 'code'
  public static FILTER_NAME: string = 'name'
  public static FILTER_ID: string = 'id'

  // Action
  public static ACTION_READ: string = 'READ'
  public static ACTION_CREATE: string = 'CREATE'
  public static ACTION_UPDATE: string = 'UPDATE'
  public static ACTION_DELETE: string = 'DELETE'

  // Menu
  public static IDESHOP_DASHBOARD: string = 'IDESHOP_DASHBOARD'
  public static IDESHOP_USER: string = 'IDESHOP_USER'
  public static IDESHOP_BUSINESS_USER: string = 'IDESHOP_BUSINESS_USER'
  public static ADMIN_IDESHOP_BUSINESS: string = 'ADMIN_IDESHOP_BUSINESS'
  public static IDESHOP_ORDER_CUSTOMER: string = 'IDESHOP_ORDER_CUSTOMER'
  public static IDESHOP_SETTING: string = 'IDESHOP_SETTING'
  public static ADMIN_IDESHOP_PORTAL: string = 'ADMIN_IDESHOP_PORTAL'
  public static ADMIN_IDESHOP_DASHBOARD: string = 'ADMIN_IDESHOP_DASHBOARD'
  public static IDESHOP_BUSINESS: string = 'IDESHOP_BUSINESS'
  public static IDESHOP_ANALYTIC: string = 'IDESHOP_ANALYTIC'
  public static IDESHOP_INTEGRATION: string = 'IDESHOP_INTEGRATION'
  public static IDESHOP_PRODUCT: string = 'IDESHOP_PRODUCT'
  public static IDESHOP_BUSINESS_ROLE: string = 'IDESHOP_BUSINESS_ROLE'
  public static IDESHOP_ROLE: string = 'IDESHOP_ROLE'
  public static IDESHOP_PROMOTION_COUPON: string = 'IDESHOP_PROMOTION_COUPON'

  // Permission classified by user type
  public static IDESHOP_SA_ROLE: string = 'IDESHOP_SA_ROLE'
  public static IDESHOP_SA_USER: string = 'IDESHOP_SA_USER'
  public static IDESHOP_SA_BUSINESS: string = 'IDESHOP_SA_BUSINESS'
  public static IDESHOP_SA_BUSINESS_PRODUCT: string = 'IDESHOP_SA_PRODUCT'
  public static IDESHOP_SA_BUSINESS_INTEGRATION: string = 'IDESHOP_SA_INTEGRATION'
  public static IDESHOP_SA_BUSINESS_ORDER: string = 'IDESHOP_SA_ORDER'
  public static IDESHOP_SA_BUSINESS_CUSTOMER: string = 'IDESHOP_SA_CUSTOMER'
  public static IDESHOP_SA_BUSINESS_PROMOTION_COUPON: string = 'IDESHOP_SA_PROMOTION_COUPON'
  public static IDESHOP_SA_BUSINESS_SETTING: string = 'IDESHOP_SA_SETTING'
  public static IDESHOP_SA_BUSINESS_ROLE: string = 'IDESHOP_SA_BUSINESS_ROLE'
  public static IDESHOP_SA_BUSINESS_USER: string = 'IDESHOP_SA_BUSINESS_USER'
  public static IDESHOP_SA_BUSINESS_SETTING_DELIVERY: string = 'IDESHOP_SA_SETTING_DELIVERY'
  public static IDESHOP_SA_BUSINESS_APPEREANCE: string = 'IDESHOP_SA_APPEREANCE'
  public static IDESHOP_SA_BUSINESS_BRANCH: string = 'IDESHOP_SA_BRANCH'
  public static IDESHOP_SA_BUSINESS_SETTING_TAX: string = 'IDESHOP_SA_SETTING_TAX'
  public static IDESHOP_SA_BUSINESS_SETTING_CONTACT: string = 'IDESHOP_SA_SETTING_CONTACT'
  public static IDESHOP_SA_BUSINESS_SETTING_OPERATIONAL: string = 'IDESHOP_SA_SETTING_OPERATIONAL'
  public static IDESHOP_SA_BUSINESS_SETTING_SCHEDULED_ORDER: string = 'IDESHOP_SA_SETTING_SCHEDULED_ORDER'
  public static IDESHOP_SA_BUSINESS_BRANCH_STORE: string = 'IDESHOP_SA_BRANCH_STORE'
  public static IDESHOP_SA_BUSINESS_BRANCH_POS: string = 'IDESHOP_SA_BRANCH_POS'
  public static IDESHOP_SA_BUSINESS_PAYOUT: string = 'IDESHOP_SA_PAYOUT'
  public static IDESHOP_SA_EMPLOYEE: string = 'IDESHOP_SA_EMPLOYEE'
  public static IDESHOP_SA_SETTING_ROUNDING: string = 'IDESHOP_SA_SETTING_ROUNDING'
  public static IDESHOP_SA_TABLE_MANAGEMENT: string = 'IDESHOP_SA_TABLE_MANAGEMENT'
  public static IDESHOP_SA_MERCHANT_LOG: string = 'IDESHOP_SA_MERCHANT_LOG'
  public static IDESHOP_SA_BUSINESS_ANALYTIC_PRODUCT: string = 'IDESHOP_SA_ANALYTIC_PRODUCT'
  public static IDESHOP_SA_BUSINESS_ANALYTIC_TRANSACTION: string = 'IDESHOP_SA_ANALYTIC_TRANSACTION'
  public static IDESHOP_SA_BUSINESS_ANALYTIC_SHIFT: string = 'IDESHOP_SA_ANALYTIC_SHIFT'
  public static IDESHOP_SA_BUSINESS_ANALYTIC_PROMOTION: string = 'IDESHOP_SA_ANALYTIC_PROMOTION'
  public static IDESHOP_SA_BUSINESS_ANALYTIC_CATEGORY: string = 'IDESHOP_SA_ANALYTIC_CATEGORY'
  public static IDESHOP_SA_BUSINESS_ANALYTIC_PAYMENT_RECONCILIATION: string =
    'IDESHOP_SA_ANALYTIC_PAYMENT_RECONCILIATION'
  public static IDESHOP_SA_SETTING_RECEIPT: string = 'IDESHOP_SA_SETTING_RECEIPT'
  public static IDESHOP_SA_CUSTOMER_DISPLAY: string = 'IDESHOP_SA_CUSTOMER_DISPLAY'
  public static IDESHOP_SA_QUEUE_NUMBER_DISPLAY: string = 'IDESHOP_SA_QUEUE_NUMBER_DISPLAY'
  public static IDESHOP_SA_INTEGRATION_DELIVERY_PARENT: string = 'IDESHOP_SA_INTEGRATION_DELIVERY_PARENT'
  public static IDESHOP_SA_SETTING_CASH_DRAWER: string = 'IDESHOP_SA_SETTING_CASH_DRAWER'
  public static IDESHOP_SA_MARKETING_AND_FINANCE_PARENT: string = 'IDESHOP_SA_MARKETING_AND_FINANCE_PARENT'
  public static IDESHOP_SA_NPS_PARENT: string = 'IDESHOP_SA_NPS_PARENT'
  public static IDESHOP_SA_LOG_PARENT: string = 'IDESHOP_SA_LOG_PARENT'
  public static IDESHOP_SA_SETTING_ORDER_STATUS_PARENT: string = 'IDESHOP_SA_SETTING_ORDER_STATUS_PARENT'
  public static IDESHOP_SA_KIOSK: string = 'IDESHOP_SA_KIOSK'
  public static IDESHOP_SA_KDS: string = 'IDESHOP_SA_KDS'
  public static IDESHOP_SA_CLOUD_PRINTER: string = 'IDESHOP_SA_CLOUD_PRINTER'
  public static IDESHOP_SA_INTEGRATION_PAYMENT = 'IDESHOP_SA_INTEGRATION_PAYMENT'
  public static IDESHOP_SA_GDC = 'IDESHOP_SA_GDC'
  public static IDESHOP_SA_SETTING_TIP: string = 'IDESHOP_SA_SETTING_TIP'

  public static IDESHOP_ADM_BUSINESS: string = 'IDESHOP_ADM_BUSINESS'
  public static IDESHOP_ADM_PRODUCT: string = 'IDESHOP_ADM_PRODUCT'
  public static IDESHOP_ADM_INTEGRATION: string = 'IDESHOP_ADM_INTEGRATION'
  public static IDESHOP_ADM_ORDER: string = 'IDESHOP_ADM_ORDER'
  public static IDESHOP_ADM_CUSTOMER: string = 'IDESHOP_ADM_CUSTOMER'
  public static IDESHOP_ADM_SETTING: string = 'IDESHOP_ADM_SETTING'
  public static IDESHOP_ADM_ROLE: string = 'IDESHOP_ADM_ROLE'
  public static IDESHOP_ADM_USER: string = 'IDESHOP_ADM_USER'
  public static IDESHOP_ADM_SETTING_DELIVERY: string = 'IDESHOP_ADM_SETTING_DELIVERY'
  public static IDESHOP_ADM_PROMOTION_COUPON: string = 'IDESHOP_ADM_PROMOTION_COUPON'
  public static IDESHOP_ADM_SETTING_OPERATIONAL: string = 'IDESHOP_ADM_SETTING_OPERATIONAL'
  public static IDESHOP_ADM_SETTING_SCHEDULED_ORDER: string = 'IDESHOP_ADM_SETTING_SCHEDULED_ORDER'
  public static IDESHOP_ADM_SETTING_CONTACT: string = 'IDESHOP_ADM_SETTING_CONTACT'
  public static IDESHOP_ADM_BRANCH: string = 'IDESHOP_ADM_BRANCH'
  public static IDESHOP_ADM_ANALYTIC_TRANSACTION: string = 'IDESHOP_ADM_ANALYTIC_TRANSACTION'
  public static IDESHOP_ADM_ANALYTIC_PRODUCT: string = 'IDESHOP_ADM_ANALYTIC_PRODUCT'
  public static IDESHOP_ADM_SETTING_TAX: string = 'IDESHOP_ADM_SETTING_TAX'
  public static IDESHOP_ADM_APPEREANCE: string = 'IDESHOP_ADM_APPEREANCE'
  public static IDESHOP_ADM_BRANCH_STORE: string = 'IDESHOP_ADM_BRANCH_STORE'
  public static IDESHOP_ADM_BRANCH_POS: string = 'IDESHOP_ADM_BRANCH_POS'
  public static IDESHOP_ADM_PAYOUT: string = 'IDESHOP_ADM_PAYOUT'
  public static IDESHOP_ADM_EMPLOYEE: string = 'IDESHOP_ADM_EMPLOYEE'
  public static IDESHOP_ADM_MERCHANT_LOG: string = 'IDESHOP_ADM_MERCHANT_LOG'
  public static IDESHOP_ADM_ANALYTIC_SHIFT: string = 'IDESHOP_ADM_ANALYTIC_SHIFT'
  public static IDESHOP_ADM_SETTING_ROUNDING: string = 'IDESHOP_ADM_SETTING_ROUNDING'
  public static IDESHOP_ADM_ANALYTIC_PROMOTION: string = 'IDESHOP_ADM_ANALYTIC_PROMOTION'
  public static IDESHOP_ADM_ANALYTIC_CATEGORY: string = 'IDESHOP_ADM_ANALYTIC_CATEGORY'
  public static IDESHOP_ADM_ANALYTIC_PAYMENT_RECONCILIATION: string = 'IDESHOP_ADM_ANALYTIC_PAYMENT_RECONCILIATION'
  public static IDESHOP_ADM_TABLE_MANAGEMENT: string = 'IDESHOP_ADM_TABLE_MANAGEMENT'
  public static IDESHOP_ADM_SETTING_RECEIPT: string = 'IDESHOP_ADM_SETTING_RECEIPT'
  public static IDESHOP_ADM_CUSTOMER_DISPLAY: string = 'IDESHOP_ADM_CUSTOMER_DISPLAY'
  public static IDESHOP_ADM_QUEUE_NUMBER_DISPLAY: string = 'IDESHOP_ADM_QUEUE_NUMBER_DISPLAY'
  public static IDESHOP_ADM_SETTING_ORDER_STATUS_PARENT: string = 'IDESHOP_ADM_SETTING_ORDER_STATUS_PARENT'
  public static IDESHOP_ADM_KIOSK: string = 'IDESHOP_ADM_KIOSK'
  public static IDESHOP_ADM_KDS: string = 'IDESHOP_ADM_KDS'
  public static IDESHOP_ADM_SETTING_TIP: string = 'IDESHOP_ADM_SETTING_TIP'

  /** Close Common Aliases */

  /** Open ~ Actions Keywords */
  public static PERMISSION_READ: string = 'READ'
  public static PERMISSION_CREATE: string = 'CREATE'
  public static PERMISSION_UPDATE: string = 'UPDATE'
  public static PERMISSION_DELETE: string = 'DELETE'
  /** Close ~ Actions Keywords */

  /** Open ~ System Keywords */
  public static PREFIX_ADMIN_IDESHOP: string = '_SA_'
  public static PREFIX_MERCHANT_IDESHOP: string = '_ADM_'
  public static PREFIX_IDESHOP: string = 'IDESHOP'
  public static PREFIX_IDEPOS: string = 'IDEPOS'
  public static PREFIX_IDEPAGE: string = 'IDEPAGE'
  public static KEYWORD_BUSINESS: string = 'BUSINESS'
  public static BUSINESS_TYPE_RETAIL: string = 'GENERAL'
  public static BUSINESS_TYPE_MENU: string = 'MENU'
  /** Close ~ System Keywords */

  /** Open ~ Utilies Url */
  public static UTILITIES_URL: string = '/api/utilities'
  public static UTILITIES_COUNTRY: string = 'country'
  public static UTILITIES_CURRENCY: string = 'currency'
  public static UTILITIES_TIMEZONE: string = 'timezone'
  public static UTILITIES_PHONE_COUNTRY_CODE: string = 'phone-number'
  public static UTILITIES_BUSINESS_TEMPLATE: string = 'template'
  public static UTILITIES_BUSINESS_TIER: string = 'tier'
  /** Close ~ Utilies Url */

  /** Open ~ Branch Url */
  public static BRANCH_STORE_URL: string = '/api/idepos/store'
  public static BRANCH_STORE_ID: string = 'storeId'
  public static STORE_DROPDOWN: string = 'dropdown-list'
  /** Open ~ Branch Url */

  /** Open ~ POS Device Url */
  public static POS_DEVICE_URL: string = '/api/idepos/pos-devices'
  public static POS_DEVICE_LOGOUT_URL: string = 'logout'

  // Super Admin
  public static SUPER_ADMIN_POS_DEVICE_URL = '/api/merchant/admin/business-section'

  // Stuff
  public static POS_DEVICE: string = 'pos-devices'
  /** Open ~ POS Device Url */

  /** Open ~ Dashboard Url */
  public static DASHBOARD_MENU_URL: string = '/api/merchant/analytics/item-dashboard'
  public static DASHBOARD_SUMMARY_DETAIL_URL: string = '/api/merchant/analytics/item-detail-dashboard'
  public static DASHBOARD_SUMMARY_CATEGORY = '/api/merchant/analytics/get-analytics-category-summary'

  public static DASHBOARD_DOWNLOAD_REQ_URL: string = '/api/merchant/analytics/download'
  public static DASHBOARD_ITEM: string = 'item-dashboard'
  public static DASHBOARD_ANALYTIC_CSV: string = 'analytic-dashboard-csv'
  public static DASHBOARD_ANALYTIC_SUMMARY_CSV: string = 'analytic-dashboard-summary-csv'
  public static ANALYTIC_ITEM_CSV: string = 'analytic-item-csv'
  public static ANALYTIC_CATEGORY_CSV: string = 'analytic-category-csv'

  // template Retail
  public static DASHBOARD_URL_RETAIL: string = '/api/merchant/v3'
  public static DASHBOARD_RETAIL_DASHBOARD: string = 'dashboard'
  public static DASHBOARD_RETAIL_BALANCE: string = 'dashboard-balance'

  // Onboarding stuff
  public static DASHBOARD_CLOSE_ONBOARDING: string = `${this.DASHBOARD_URL_RETAIL}/setup/action-close-onboarding`
  /** Close ~ Dashboard Url */

  /** Open ~ Withdraw Url */
  public static WITHDRAW_URL: string = `${this.URL_MERCHANT_V3}/payout`
  public static WITHDRAW_INIT: string = 'initialize'
  public static WITHDRAW_STEP: string = 'step-'
  public static WITHDRAW_AUTH: string = `${this.WITHDRAW_URL}/${this.WITHDRAW_STEP}a`
  public static WITHDRAW_AUTH_2: string = `${this.WITHDRAW_URL}/${this.WITHDRAW_STEP}b`
  public static WITHDRAW_RESEND_PIN: string = `${this.WITHDRAW_URL}/${this.WITHDRAW_STEP}c`
  /** Close ~ Withdraw Url */

  /** Open ~ Countries Names */
  public static COUNTRY_INDONESIA: string = 'INDONESIA'
  public static COUNTRY_SINGAPORE: string = 'SINGAPORE'
  public static COUNTRY_CAMBODIA: string = 'CAMBODIA'
  public static COUNTRY_CHINESE: string = 'CHINESE'
  public static COUNTRY_PHILIPPINES: string = 'PHILIPPINES'
  public static COUNTRY_USA: string = 'UNITED_STATE_AMERICA'
  /** Close ~ Countries Names */

  /** Open ~ Countries Ids */
  public static COUNTRY_ID_INDONESIA: string = 'ID'
  public static COUNTRY_ID_SINGAPORE: string = 'SG'
  public static COUNTRY_ID_CAMBODIA: string = 'KH'
  public static COUNTRY_ID_PHILIPPINES: string = 'PH'
  public static COUNTRY_ID_USA: string = 'US'
  public static COUNTRY_ID_LAOS: string = 'LA'
  public static COUNTRY_ID_TAIWAN: string = 'TW'
  public static COUNTRY_ID_AUSTRALIA: string = 'AU'
  /** Close ~ Countries Ids */

  /** Open ~ Common Variable */
  public static ADMIN_URL = 'admin/business-section'
  /** Close ~ Common Variable */

  /** Open ~ Employee Urls */
  public static EMPLOYEE_URL = `${this.URL_MERCHANT_V3}/employee`
  /** Close ~ Employee Urls */

  /** Open ~ Table Management Url */
  public static TABLE_MANAGEMENT_URL = `${this.URL_MERCHANT_V3}/table`
  public static TABLE_QR = '-qr'
  /** Close ~ Table Management Url */
}
