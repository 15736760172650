import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AppSettings } from '@configs/app.setting'
import {
  JobQueueCheck,
  NpsPayload,
  ResponseModel,
  TourPayloadModel,
  TourResponseModel,
  TutorialVideoParam,
  TutorialVideoResponse,
} from '@core/models/common.model'
import { map, Observable, tap } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class CommonRepositoryService {
  constructor(private _httpClient: HttpClient) {}

  connectionTest(): Observable<any> {
    return this._httpClient.get(AppSettings.CONNECTION_TEST_URL)
  }

  countryList(): Observable<any> {
    return this._httpClient.get(`${AppSettings.UTILITIES_URL}/${AppSettings.UTILITIES_COUNTRY}`)
  }

  currencyList(): Observable<any> {
    return this._httpClient.get(`${AppSettings.UTILITIES_URL}/${AppSettings.UTILITIES_CURRENCY}`)
  }

  timezoneList(): Observable<any> {
    return this._httpClient.get(`${AppSettings.UTILITIES_URL}/${AppSettings.UTILITIES_TIMEZONE}`)
  }

  phoneCountryCodeList(): Observable<any> {
    return this._httpClient.get(`${AppSettings.UTILITIES_URL}/${AppSettings.UTILITIES_PHONE_COUNTRY_CODE}`)
  }

  businessTemplateList(): Observable<any> {
    return this._httpClient.get(`${AppSettings.UTILITIES_URL}/${AppSettings.UTILITIES_BUSINESS_TEMPLATE}`)
  }

  businessTierList(): Observable<any> {
    return this._httpClient.get(`/api/utilities/tier`)
  }

  /**
   * Download
   */

  checkDownloadRequest(checkToken: string): Observable<JobQueueCheck | any> {
    return this._httpClient.get(`${AppSettings.JOB_QUEUE_URL}/${AppSettings.JOB_QUEUE_CHECKING}/${checkToken}`)
  }

  downloadProduct(downloadToken: string): Observable<any> {
    return this._httpClient.get(`${AppSettings.JOB_QUEUE_URL}/${AppSettings.JOB_QUEUE_DOWNLOAD}/${downloadToken}`, {
      responseType: 'blob',
    })
  }

  checkRegion(): Observable<any> {
    return this._httpClient.get('check-region')
  }

  getTourStatus(menuType: string): Observable<ResponseModel<TourResponseModel>> {
    return this._httpClient.get(`/api/merchant/setup?menuType=${menuType}`)
  }

  setTourStatus(payload: TourPayloadModel): Observable<ResponseModel<any>> {
    return this._httpClient.post(`/api/merchant/setup`, payload)
  }

  getTutorialVideo(params: TutorialVideoParam): Observable<ResponseModel<TutorialVideoResponse>> {
    let url: string = '/api/video/link/subMenu?'

    for (const param in params) {
      if (url.charAt(url.length - 1) === '?') {
        url = `${url}${param}=${params[param]}`
      } else {
        url = `${url}&${param}=${params[param]}`
      }
    }

    return this._httpClient.get(url)
  }

  submitNps(payload: NpsPayload): Observable<ResponseModel<any>> {
    return this._httpClient.post(`/api/merchant/nps`, payload)
  }

  checkCompletedSetup(): Observable<boolean> {
    return this._httpClient.get(`/api/merchant/setup/get-setUp`).pipe(
      map((response: ResponseModel<any>) => {
        return (
          response.data.merchantSetupConfigModel.stepA &&
          response.data.merchantSetupConfigModel.stepB &&
          response.data.merchantSetupConfigModel.stepC &&
          response.data.merchantSetupConfigModel.stepD &&
          !response.data.nps
        )
      }),
      tap({
        next: (response) => response,
      })
    )
  }

  urlChecker(url: string): Observable<any> {
    return this._httpClient.post('url-checker', { url: url })
  }
}
