import { Injectable } from '@angular/core'
import { AppSettings } from '@configs/app.setting'
import { PaginationMode, ReqPagination } from '@core/models/common.model'
import { TranslateService } from '@ngx-translate/core'
import {
  AdminBusinessDetail,
  BusinessListPaginationConfig,
  NewBusinessListPageConfig,
  ResultBusinessListPagination,
} from 'src/app/admin/business/ui/admin-business.model'
import { ReplaySubject, Observable } from 'rxjs'
import { Router } from '@angular/router'
import { AdminStorageList } from 'src/app/admin/admin-storage-list'
import { removesLocalstorage } from '@utils/app-stuff'
import { AdminBusinessRespositoryService } from 'src/app/admin/business/data-access/repository/admin-business-repository/admin-business-respository.service'

@Injectable({
  providedIn: 'root',
})
export class AdminBusinessService {
  constructor(
    private _translateService: TranslateService,
    private _businessRepository: AdminBusinessRespositoryService,
    private _router: Router
  ) {}

  BUSINESS_PAGINATION: string = AdminStorageList.BUSINESS_PAGINATION
  BUSINESS_MONITORED_INFO: string = AdminStorageList.BUSINESS_MONITORED_INFO

  setBusinessPaginationConfig(config: NewBusinessListPageConfig) {
    localStorage.setItem(this.BUSINESS_PAGINATION, JSON.stringify(config))
  }

  getBusinessPaginationConfig() {
    let result: any = localStorage.getItem(this.BUSINESS_PAGINATION)
    return JSON.parse(result)
  }

  getBusinessStatus(businessStatus: number): string {
    var result: string = ''
    switch (businessStatus) {
      case AppSettings.STATUS_ACTIVE:
        result = this._translateService.instant('BUSINESSES.BUSINESS_STATUS_ACTIVE')
        break
      case AppSettings.STATUS_PENDING:
        result = this._translateService.instant('BUSINESSES.BUSINESS_STATUS_PENDING')
        break
      case AppSettings.STATUS_NEED_ACTIVATION:
        result = this._translateService.instant('BUSINESSES.BUSINESS_STATUS_NEED_APPROVAL')
        break
    }
    return result
  }

  setMonitoredBusiness(businessInfo: AdminBusinessDetail | null) {
    localStorage.setItem(this.BUSINESS_MONITORED_INFO, JSON.stringify(businessInfo))
  }

  getMonitoredBusiness(): any {
    let result: any = localStorage.getItem(this.BUSINESS_MONITORED_INFO)
    return JSON.parse(result)
  }

  destroyMonitoredBusiness() {
    localStorage.setItem(this.BUSINESS_MONITORED_INFO, JSON.stringify(null))
  }

  setSuperAdminDetail() {
    const getCountry = localStorage.getItem(AppSettings.COOKIE_SA_COUNTRY)
    const getCurrency = localStorage.getItem(AppSettings.COOKIE_SA_CURRENCY)
    const getCurrencyLabel = localStorage.getItem(AppSettings.COOKIE_SA_CURRENCY_LABEL)
    const getCurrencySymbol = localStorage.getItem(AppSettings.COOKIE_SA_CURRENCY_SYMBOL)
    const getTimezoneOffset = localStorage.getItem(AppSettings.LOCALSTORAGE_SA_TIMEZONE_OFFSET)

    this.destroyMonitoredBusiness()

    localStorage.setItem(AppSettings.COOKIE_COUNTRY, getCountry!)
    localStorage.setItem(AppSettings.COOKIE_CURRENCY, getCurrency!)
    localStorage.setItem(AppSettings.COOKIE_CURRENCY_LABEL, getCurrencyLabel!)
    localStorage.setItem(AppSettings.COOKIE_CURRENCY_SYMBOL, getCurrencySymbol!)
    localStorage.setItem(AppSettings.TIMEZONE_OFFSET, getTimezoneOffset!)
    

    // Clean localstorages
    const exceptionList: string[] = Object.keys(AdminStorageList).map((storageName) => AdminStorageList[storageName])
    removesLocalstorage(exceptionList)
    this._router.navigate([`./admin/${AppSettings.SETTINGS_BUSINESS_MENU}`])
  }

  getMonitoredBusinessDefaultStoreId(): number {
    let result: number = 0
    const monitoredBusiness: AdminBusinessDetail | null = this.getMonitoredBusiness()
    if (monitoredBusiness) {
      result = monitoredBusiness.storeId || 0
    }
    return result
  }

  getMonitoredBusinessId(): number {
    let result: number = 0
    const monitoredBusiness: AdminBusinessDetail | null = this.getMonitoredBusiness()
    if (monitoredBusiness) {
      result = monitoredBusiness.id || 0
    }

    return result
  }

  /**
   *
   * @param value
   * @param mode
   * @param paginationConfig
   * @returns businessList
   */
  adminBusinessListPaginationHandler(
    value: string | number | any,
    mode: PaginationMode,
    paginationConfig: BusinessListPaginationConfig
  ): Observable<ResultBusinessListPagination> {
    const resultPaginationConfig: BusinessListPaginationConfig = new BusinessListPaginationConfig()
    let $result: any = new ReplaySubject()
    var resultBusinessList: ResultBusinessListPagination = {
      paginationConfig: paginationConfig,
      contents: [],
      isUpdated: false,
    }

    resultPaginationConfig.sortDirection = paginationConfig.sortDirection
    resultPaginationConfig.status = paginationConfig.status
    resultPaginationConfig.pageSize = paginationConfig.pageSize
    resultPaginationConfig.dataQuery = paginationConfig.dataQuery

    switch (mode) {
      case 'page':
        // With API
        paginationConfig.pageNumber = Number(value)

        // this._businessService.setBusinessPaginationConfig(paginationConfig);
        this._businessRepository.adminBusinessList(paginationConfig).subscribe(
          (response) => {
            resultPaginationConfig.totalItem = response.data.totalElements
            resultPaginationConfig.pageNumber = Number(value)
            resultBusinessList.contents = response.data.content
          },
          (errorResponse: any) => {
            console.error(errorResponse)
            resultBusinessList.contents = []
          },
          () => {
            resultBusinessList.isUpdated = true
            $result.next(resultBusinessList)
          }
        )

        break
      case 'search':
        // With API
        resultPaginationConfig.dataQuery = value as string
        resultPaginationConfig.pageNumber = 0

        // this._businessService.setBusinessPaginationConfig(paginationConfig);
        this._businessRepository.adminBusinessList(paginationConfig).subscribe(
          (response) => {
            resultPaginationConfig.pageNumber = 0
            resultPaginationConfig.maxPageNumber = response.data.totalPages
            resultPaginationConfig.totalItem = response.data.totalElements
            resultBusinessList.paginationConfig = resultPaginationConfig
            resultBusinessList.contents = response.data.content
          },
          (errorResponse: any) => {
            console.error(resultBusinessList)
            resultBusinessList.contents = []
          },
          () => {
            resultBusinessList.isUpdated = true
            $result.next(resultBusinessList)
          }
        )

        break
      case 'filter':
        if (value != paginationConfig.status) {
          resultPaginationConfig.status = value

          // With API
          resultPaginationConfig.pageNumber = 0
          resultPaginationConfig.status = paginationConfig.status
          resultPaginationConfig.pageNumber = paginationConfig.pageNumber

          // this._businessService.setBusinessPaginationConfig(paginationConfig);
          this._businessRepository.adminBusinessList(paginationConfig).subscribe(
            (response) => {
              resultPaginationConfig.maxPageNumber = response.data.totalPages
              resultPaginationConfig.totalItem = response.data.totalElements
              resultBusinessList.paginationConfig = resultPaginationConfig
              resultBusinessList.contents = response.data.content
            },
            (errorResponse: any) => {
              console.error(errorResponse)
              resultBusinessList.contents = []
            },
            () => {
              resultBusinessList.isUpdated = true
              $result.next(resultBusinessList)
            }
          )
        }

        break
      default:
        resultBusinessList.paginationConfig = paginationConfig
        $result.next(resultBusinessList)
        break
    }

    return $result.asObservable(resultBusinessList)
  }

  /**
   *
   * @param value
   * @param mode
   * @param paginationConfig
   * @returns businessList
   */
  businessListPaginationHandler(
    value: string | number | any,
    mode: PaginationMode,
    paginationConfig: BusinessListPaginationConfig
  ): Observable<ResultBusinessListPagination> {
    const resultPaginationConfig: BusinessListPaginationConfig = new BusinessListPaginationConfig()
    let $result: any = new ReplaySubject()
    var resultBusinessList: ResultBusinessListPagination = {
      paginationConfig: paginationConfig,
      contents: [],
      isUpdated: false,
    }

    resultPaginationConfig.sortDirection = paginationConfig.sortDirection
    resultPaginationConfig.pageSize = paginationConfig.pageSize
    resultPaginationConfig.dataQuery = paginationConfig.dataQuery
    if (paginationConfig.status) resultPaginationConfig.status = paginationConfig.status

    switch (mode) {
      case 'page':
        // With API
        paginationConfig.pageNumber = Number(value)

        // this._businessService.setBusinessPaginationConfig(paginationConfig);
        this._businessRepository.businessList(paginationConfig, this.getMonitoredBusinessId()).subscribe(
          (response) => {
            resultPaginationConfig.totalItem = response.data.totalElements
            resultPaginationConfig.pageNumber = Number(value)
            resultBusinessList.contents = response.data.content
          },
          (errorResponse: any) => {
            console.error(errorResponse)
            resultBusinessList.contents = []
          },
          () => {
            resultBusinessList.isUpdated = true
            $result.next(resultBusinessList)
          }
        )

        break
      case 'search':
        // With API
        paginationConfig.pageNumber = 0
        paginationConfig.dataQuery = value || ''
        resultPaginationConfig.dataQuery = paginationConfig.dataQuery
        resultPaginationConfig.pageNumber = 0

        this._businessRepository.businessList(paginationConfig, this.getMonitoredBusinessId()).subscribe(
          (response) => {
            resultPaginationConfig.pageNumber = 0
            resultPaginationConfig.maxPageNumber = response.data.totalPages
            resultPaginationConfig.totalItem = response.data.totalElements
            resultBusinessList.paginationConfig = resultPaginationConfig
            resultBusinessList.contents = response.data.content
          },
          (errorResponse: any) => {
            console.error(resultBusinessList)
            resultBusinessList.contents = []
          },
          () => {
            resultBusinessList.isUpdated = true
            $result.next(resultBusinessList)
          }
        )

        break
      case 'filter':
        if (value != paginationConfig.status) {
          resultPaginationConfig.status = value

          // With API
          resultPaginationConfig.pageNumber = 0
          resultPaginationConfig.status = paginationConfig.status
          resultPaginationConfig.pageNumber = paginationConfig.pageNumber

          // this._businessService.setBusinessPaginationConfig(paginationConfig);
          this._businessRepository.businessList(paginationConfig, this.getMonitoredBusinessId()).subscribe(
            (response) => {
              resultPaginationConfig.maxPageNumber = response.data.totalPages
              resultPaginationConfig.totalItem = response.data.totalElements
              resultBusinessList.paginationConfig = resultPaginationConfig
              resultBusinessList.contents = response.data.content
            },
            (errorResponse: any) => {
              console.error(errorResponse)
              resultBusinessList.contents = []
            },
            () => {
              resultBusinessList.isUpdated = true
              $result.next(resultBusinessList)
            }
          )
        }

        break
      default:
        resultBusinessList.paginationConfig = paginationConfig
        $result.next(resultBusinessList)
        break
    }

    return $result.asObservable(resultBusinessList)
  }
}
